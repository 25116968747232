import React, {useState, useEffect} from 'react';
import configData from "../config.json";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import Layout from './Layout';

function Order() {   
    const [load, setLoad] = useState(true);
    const [trans, setTrans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();

     const getTransaction = async() => {
       const userId  = await localStorage.getItem('userId');
       
       setIsLoading(true);
       return fetch(`${configData.SERVER_URL}/transaction/user/${userId}`, {
         method: "get",
         headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
           "x-auth-token": window.localStorage.getItem("token") 
           },
         })
         .then((response) => response.json())
         .then((responseJson) => {
           console.log(responseJson)
           setTrans(responseJson.trans);
           setLoad(false);
           setIsLoading(false);
         })
         .catch((error) => {
           setIsLoading(false);
           console.error(error);
         });
     }
   
     useEffect(() => {
       const interval = setInterval(()=>{
         getTransaction();
       },3000)
       
       return()=>clearInterval(interval)
  },[]);


  const viewMore = (trans) => {
    window.localStorage.setItem("refNumb", trans.reference)
    window.localStorage.setItem("tranId", trans._id)
    window.location = window.location.origin + "/OrderDetails" 
  }




  return (
   <>
   

   <Layout>
   

      <section className="boxN2 border">


      <div className="topNav">
                  <div className="col-md-12 mt-2">
                      <div className="row">
                          <div className="col-4">
                          <div class="thmb-img" style={{cursor:"pointer"}}  onClick={() => navigate("/Profile")}>
                                  <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                              </div>
                          </div>
                          <div className="col-4">
                              <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Orders</h4>
                          </div>
                          <div className="col-4">
                              <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                          </div>
                      </div>
                  </div>
              </div>

          {load ? (<> 


      <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div> </> ) :

      <div class="container mt-5">

      {trans && trans.length > 0
                ? trans.map(item => {
                          return <>



          <div class="row mt-3 mb-2">
              <div class="col-md-2"></div>
              

              <div class="col-md-8 cad pt-2 pb-2 border-bottom" onClick={() => viewMore(item)} style={{cursor:'pointer'}}>
                  <div className="row">
                    <div class="col-4">
                        {/* <p>{format(new Date(item.createdAt), 'dd MMMM yy')}</p> */}
                        <p style={{fontSize:15,
                                  fontWeight:'bold'}}>{format(new Date(item.createdAt), 'dd MMMM yy')}</p>
                    </div>
                    <div class="col-4">
                        <p style={{fontSize:15,
                                  fontWeight:'bold'}}>NGN {item.amount}</p>
                    </div>
                    <div class="col-4">
                          {(item.orderStatus === "pending") && (
                          <p style={{color:'#ffd200', fontSize:15,
                          fontWeight:'bold'}}>In Progress</p> )}
                          {(item.orderStatus === "inTransit") && (
                          <p style={{color:'orange', fontSize:15,
                          fontWeight:'bold'}}>In Transit</p> )}
                          {(item.orderStatus === "success") && (
                              <p style={{fontSize:15,
                                  color:'#27ae60',
                                  fontWeight:'bold'}}>Successfull</p> )}
                    </div>
                  </div>
              </div>


              <div class="col-md-2"></div>
          </div>

          </> })

      : <section className="container mt-5">
      <div className="row justify-content-center align-items-center">
              <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>you currently do not have a order</p>
          </div>
      </section> }



      </div>
                  

          }




      </section>

 </Layout>

</>
  );
}

export default Order;
