import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Layout from '../Layout';
import configData from "../../config.json";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { format } from 'date-fns' 

const Categories = () => {
    const [show, setShow] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [cat, setCat] = useState([]);
    const [msg, setMsg] = useState("");
    const { register, handleSubmit, errors } = useForm();
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const openModal = () =>{

    }

    const addCat = (data) => {
      setisBtnLoading(true);

      if(data.name === "") {
         setMsg("Please enter category name");
         return 
      }

      return fetch(`${configData.SERVER_URL}/vendorCategory`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token":  window.localStorage.getItem("token")
        },
        body:JSON.stringify({            
          name:data.name
          })
         })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)

              if(responseJson.status === "success"){
                setisBtnLoading(false);

                  alert(responseJson.message);
              }
              if (responseJson.status === "error") {
                setisBtnLoading(false);
                  console.log(responseJson.message);
              }
          })
          .catch((error) => {
            setisBtnLoading(false);
              console.error(error);
          });

    }


  const getCat = async()=>{
    return fetch(`${configData.SERVER_URL}/vendorCategory`, {
        method: "get",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
           setCat(responseJson.data);
        })
        .catch((error) => {
        console.error(error);
        });
 }

useEffect(() => {
getCat();
},[]);


    return (
        <>


       <Layout>
  

              <div className="mt-4">
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div className="">
                           <p className="title2">CATEGORIES</p>
                        </div>
                        <div className=""  onClick={handleShow}>
                            <p className="title2">Add Category</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">

                            <div className="table-responsive">
                                <table className="table white black-text  table-hover">
                                    <thead>
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Category Name</th>
                                        <th scope="col">Date Created</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                {cat.map((c, index) => (
                                        <tr className="" key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{c.name}</td>
                                        <td>{format(new Date(c.createdAt), 'MMMM, do, yyy')}</td>
                                        <td onClick={() => openModal(c)}>
                                            <i className="fa fa-edit mr-2" ></i>
                                        </td>
                                        <td>
                                            <Link >
                                            <i className="fa fa-trash mr-2"></i>
                                            </Link>
                                        </td>
                                        </tr>
                                    ))}

                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            
  
  
          </Layout>

                

  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>


       
        <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
                <div className="row">
            <form class="col-12"  onSubmit={handleSubmit(addCat)} id="submit">    
                {msg !== "" &&
                       <p className="small alert alert-danger">{msg}</p>
                    }
                <div class="form-group">
                    <label>Enter Category Name</label>
                    <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Category Name" name="name" ref={register({ required: true, })} />
                        {errors.name && <div className="alert alert-danger" role="alert">Name is Required</div>}
                    </div>
                    <button  type="submit" name="add" class="btn btn-warn btn-block btn-sm" id="submit" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD CATEGORY</>)}</button>
                </form>                                 
                </div>
            </div>
            <div className="col-2"></div>
         </div>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

            
        </>
    );
};

export default Categories;