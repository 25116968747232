import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import HomePage from './app/Home';
import FarmAgentReg from "./main/FarmAgentReg";
import Login from "./app/Login";
import Register from "./app/Register";
import Checkout from "./app/Checkout";
import Order from "./app/Order";
import DeleteAccount from "./app/DeleteAccount";
import OrderDetails from "./app/OrderDetails";
import EditProfile from "./app/EditProfile";
import HelpDesk from "./app/HelpDesk";
import Deals from "./app/Deals";
import DealCheckout from "./app/DealCheckout";
import Categories from "./app/Categories";
import MarketTab from "./app/tabs/MarketTab";
import BasketTab from "./app/tabs/BasketTab";
import ProfileTab from "./app/tabs/ProfileTab";
import VendorIndex from "./app/BrickMortal/VendorIndex";
import VendorDetails from "./app/BrickMortal/VendorDetails";
import VendorCart from "./app/BrickMortal/VendorCart";
import VendorCheckout from "./app/BrickMortal/VendorCheckout";
import ArtisanIndex from "./app/Artisan/ArtisanIndex";

// Dashboard
import VendorLogin from "./Dashboard/Login";
import Dashboard from "./Dashboard/Vendor/Dashboard"; 
import VendorCategories from "./Dashboard/Vendor/Categories";
import Products from './Dashboard/Vendor/Products';
import Transactions from './Dashboard/Vendor/Transactions';


function App() {
  return (

    <BrowserRouter>
       <Routes>
       <Route path="/"  element={<HomePage />} exact/>
         <Route path="/Agent-Registration" 
         element={<FarmAgentReg/>} exact/>
           <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Market" element={ <MarketTab/>} />
          <Route path="/Basket" element={ <BasketTab/>} />
          <Route path="/Profile" element={ <ProfileTab/>} />
          <Route path="/Checkout" element={<Checkout/>} />
          <Route path="/Order" element={<Order/>} />
          <Route path="/OrderDetails" element={<OrderDetails />} />
          <Route path="/Delete-Account" element={<DeleteAccount />}/>
          <Route path="/EditProfile" element={<EditProfile />}/>
          <Route path="/HelpDesk"  element={<HelpDesk />} />
          <Route path="/Farm/Products" element={<Deals />}/>
           <Route path="/DealCheckout" element={<DealCheckout />}/>
           <Route path="/Categories" element={<Categories />}/> 
           <Route path="/Vendor" element={<VendorLogin />}/>
           <Route path="/Brick&Mortar" element={ <VendorIndex/>}/>
          <Route path="/store/:name/:id" element={<VendorDetails/>}/>
          <Route path="/Store/Checkout/:id" element={<VendorCheckout/>}/>
          <Route path="/Artisans" element={<ArtisanIndex/>}/>
          <Route path="/Dashboard" element={<Dashboard />}/>
          <Route path="/Product/Categories" element={<VendorCategories />}/>
          <Route path="/Products/Cart/:id" element={<VendorCart/>}/>
          <Route path="/Products" exact element={<Products />}/>
          <Route path="/Transactions" element={<Transactions />}/>
            
          
          

       </Routes>
    </BrowserRouter>
    
   
  
  );
}

export default App;
