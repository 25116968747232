import React, { useState} from 'react';
import configData from "../config.json";
import { useNavigate } from "react-router-dom";
import Layout from './Layout';





function EditProfile() {    
    let navigate = useNavigate();


    const [fullname, setFullname] = useState(localStorage.getItem('fullname'));
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [newpassword, setNewpassword] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [token, setToken] = useState();
    const [disablebtn, setDisableBtn] = useState(false);


    const updateDetails = () => {

         
     if (fullname === '' || email === '' ) {
              alert('Field is Empty')
              return;
       }
           setIsDisable(true);
          
        const token =  localStorage.getItem('token');
        const userId =  localStorage.getItem('userId');
           
         fetch(`${configData.SERVER_URL}/users/updateDetails/${(userId)}`, {
           method: "PUT",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "x-auth-token": token
           },
           body: JSON.stringify({
             fullname:fullname,
             email:email,
           })
         })
           .then(response => {
             return response.json();
           })
           .then(responseJson => {
             console.log(responseJson);
             if(responseJson.status === "success") {
                    window.localStorage.setItem("fullname", responseJson.user.fullname);
                    window.localStorage.setItem("email", responseJson.user.email);
                  alert(responseJson.message)
             }

             if (responseJson.status === 'error') {
               console.log(responseJson.message);
               alert(responseJson.message);
               return

             };
           })
           .catch(error => console.log(error));


    }

    const updatePass = async() => {
         
          if (newpassword === '' ) {
                  alert('Empty Field')
                return;
          }
         setIsDisable(true);
        
        
        const token =  localStorage.getItem('token');
        
         fetch(`${configData.SERVER_URL}/users/updatePassword`, {
           method: "post",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "x-auth-token": token
           },
           body: JSON.stringify({
            newpassword:newpassword
           })
         })
           .then(response => {
             return response.json();
           })
           .then(responseJson => {

             setIsDisable(false);
             if (responseJson.status === "success") {
                  alert(responseJson.message)
             }

             if (responseJson.status === 'error') {
               // this.setState({ disableBtn: false });
               alert(responseJson.message)
               return

             };
           })
           .catch(
             error => console.log(error.message)
            //  {setDisableBtn(false);}
             );


    }


    return (
       

<Layout>
   
   
   <section className="boxN2 border">
   
     <div className="topNav">
                    <div className="col-md-12">
                    <div className="row mt-2">
                        <div className="col-4">
                                <div class="thmb-img" style={{cursor:"pointer"}}  onClick={() => navigate("/Profile")}>
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </div>
                        </div>
                        <div className="col-4">
                            <h4  style={{color: '#000', marginTop: '10px', fontSize: '17px', textAlign:'center', fontWeight:'bold'}}>Edit Profile</h4>
                        </div>
                        <div className="col-4">
                            <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                        </div>
                    </div>
                    </div>
                </div>


    <div class="col-md-12"> 
     <div class="row">
         <div class="col-md-1"></div>
         <div class="col-md-5 mt-4">
                <div class="col-md-12 mt-3">                                        
               
                    <form class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                        <p class="mb-4">Update Details</p>  

                        <div class="form-group">
                            <input type="text"  class="form-control" placeholder="Full Name" name="fullname"   value={fullname} onChange={(e) => setFullname(e.target.value)}/>
                        </div>  
                        <div class="form-group">
                            <input type="text" class="form-control mb-4" placeholder="Email" name="email"  value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                            
                            <div class="form-group">
                                    <button onClick={()=> updateDetails()} type="submit" class="btn btn-warn btn-md my-4 btn-block waves-effect waves-light">
                                          {disablebtn ? (<>Please wait</>) : (<>Update Details</>)}
                                    </button>
                                    
                            </div>
                    </form>
                </div>
              </div>
         <div class="col-md-5 mt-4">   
                <div class="col-md-12 mt-3">                                        
               
                    <form enctype="multipart/form-data" id="submit" class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                        <p class="mb-4">Update Password</p>    
                        <div class="form-group">
                            <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password"   value={newpassword} onChange={(e) => setNewpassword(e.target.value)}/>
                        </div>
                            
                            <div class="form-group">
                                    <button onClick={()=> updatePass()} type="submit" class="btn btn-warn btn-md my-4 btn-block waves-effect waves-light" >
                                          {disablebtn ? (<>Please wait</>) : (<>Change Password</>)}
                                    </button>
                                    
                            </div>


                    </form>
                </div>
                        
         </div>
         <div class="col-md-1"></div>
         </div> 
     </div>



     </section>


</Layout>

    );
}

export default EditProfile;