
import React, {useState, useContext} from 'react';
import configData from "../config.json";
import { useForm } from "react-hook-form";
import {useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    // const [state, setState] = useContext(Context);

    const { register, handleSubmit, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);


  const vendorLogin = (data) => {
    console.log(data);

    setDisablebtn(true);


    return fetch(`${configData.SERVER_URL}/vendor/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({            
            email:data.email,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){
            window.localStorage.setItem("logo", responseJson.data.logo);
            window.localStorage.setItem("mortar", responseJson.data.role);
            window.localStorage.setItem("token", responseJson.token);
            navigate("/Dashboard");
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });
  }

    return (
        <>

<section className="h-100 gradient-form" style={{backgroundColor:" #fff"}}>
  <div className="container-fluid py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-xl-10">
        <div className="rounded-3 text-black">
          <div className="row g-0">
            <div className="col-lg-3"/>
            <div className="col-lg-6">
              <div className="card-body p-md-5 mx-md-4">

                <div className="text-center">
                  <img src="/img/icon1.png"
                    style={{width: "185px" }}alt="logo"/>
                  <h4 className="mt-1 mb-5 pb-1">IGOBI Vendors</h4>
                </div>

                <form  onSubmit={handleSubmit(vendorLogin)}>
                  <p>Please login into your account</p>

                  {message && <div className="alert alert-danger" role="alert">{message}</div>}

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example11">Email</label>
                    <input type="email" id="form2Example11" className="form-control"
                      placeholder="Email address" name="email" ref={register({ required: true, })} />
                      {errors.email && <div className="alert alert-danger" role="alert">Email Required</div>}
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example22">Password</label>
                    <input type="password" id="form2Example22" className="form-control" name="password" ref={register({ required: true, })} />
                                {errors.password && <div className="alert alert-danger" role="alert">Password Required</div>}
                  </div>

                  <div className="text-center pt-1 mb-5 pb-1">
                    <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="submit">{disablebtn ? (<>Please wait...</>) : (<>Login</>)}</button>

                    {/* <a className="text-muted" href="#!">Forgot password?</a> */}
                  </div>


                </form>

              </div>
            </div>
            <div className="col-lg-3"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            
        </>
    );
};

export default Login;