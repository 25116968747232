

import React, { useState, useEffect }  from 'react';
import Modal from 'react-bootstrap/Modal';
import Layout from '../Layout';
import configData from "../../config.json";

const Products = () => {
    const [show, setShow] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [product, setProduct] = useState([])
    const [prodId, setProdId] = useState('')
  
  const updateProd = async (e) => {
  setisBtnLoading(true);
  
        e.preventDefault()
        
        const formData = new FormData()
        formData.append('productImage', productImage)
      
            try {
      const response = await fetch(`${configData.SERVER_URL}/product/update/${prodId}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "x-auth-token": window.localStorage.getItem("token")
        },
        body: formData
      });
      const responseJson = await response.json();
      if (responseJson.status === "success") {
        setisBtnLoading(false);

        alert(responseJson.message);
      }
      if (responseJson.status === "error") {
        console.log(responseJson.message);
      }
    } catch (error) {
      setisBtnLoading(false);
      console.error(error);
    }
   
  }
  
    const getProduct = async () => {

           
    try {
        const response = await fetch(`${configData.SERVER_URL}/vendorProducts/vendors/Product`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token")
          },
        });
        const responseJson = await response.json();
        console.log(responseJson);
        setProduct(responseJson.data);
      } catch (error) {
        console.error(error);
      }
    }
  
  //   useEffect(() => {
  //     // const interval=setInterval(()=>{
  //       getProduct();
  //     //  },3000)
        
  //     //  return()=>clearInterval(interval)
  //  },[]);
  
   const editProduct = (product) => {
       setProdId(product._id);
  
       window.$("#modal3").modal("show") 
  
   }


   const [cat, setCat] = useState([])
   const [productImage, setProductImage] = useState('');
   const [name, setName] = useState("");
   const [price, setPrice] = useState();
   const [description, setDescription] = useState("")
   const [categoryId, setCategoryId] = useState("");
   const [isLoading, setIsLoading] = useState(false)
   const [msg, setMsg] = useState("");


   const addProd = async (e) => {
    setisBtnLoading(true);
  
          e.preventDefault();
  
          const formData = new FormData();
          formData.append('productImage', productImage);
          formData.append('name', name);
          formData.append('price', price);
          formData.append('description', description);
          formData.append('categoryId', categoryId);

          console.log(categoryId);

        // const form = new FormData(document.getElementById("submit"))
        
              try {
       const response = await fetch(`${configData.SERVER_URL}/vendorProducts`, {
         method: "post",
         headers: {
           // Accept: "application/json",
           "x-auth-token": window.localStorage.getItem("token")
         },
         body: formData
       });
       const responseJson = await response.json();
       if (responseJson.status === "success") {
         setisBtnLoading(false);

         alert(responseJson.message);
       }
       if (responseJson.status === "error") {
         setisBtnLoading(false);
         setMsg(responseJson.message);
         console.log(responseJson.message);
       }
     } catch (error) {
       setisBtnLoading(false);
       console.error(error);
     }
     
    }
  
    const categ = async () => {
           
      try {
        const response = await fetch(`${configData.SERVER_URL}/vendorCategory`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token")
          },
        });
        const responseJson = await response.json();
        console.log(responseJson);
        setCat(responseJson.data);
      } catch (error) {
        console.error(error);
      }
    }
  
    useEffect(() => {

      getProduct();
      categ();
   },[]);
  
  

    return (
        <>


       <Layout>
  

              <div className=" mt-4">
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div className="">
                           <p className="title2">PRODUCTS</p>
                        </div>
                        <div className=""  onClick={handleShow}>
                            <p className="title2">Add Product</p>
                        </div>
                    </div>

                    


                            <div className="col-md-12 mt-3">
                                <div className="row">

            {isLoading ? <>Loading </> : <>
                    <div className="table-responsive">
                        <table className="table white black-text  table-hover">
                            <thead>
                                <tr>
                                {/* <th scope="col">Users Name</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Vendors Name</th>
                                <th scope="col">Agents Name</th> */}
                                </tr>
                            </thead>
                        <tbody>
                        {product && product.length > 0
                            ?  product.map((p, index) => {
                            return <>
                        <tr key={index + 1}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <img src={`${configData.PIC_URL}/${p.productImage}`} className="img-fluid img-thumbnail tableImg" alt=""/>
                          </td>
                          <td>{p.name}</td>
                          <td>#{p.price}</td>
                          <td>{p.status}</td>
                        </tr>

                      </>})
                      : <div className="col-md-12"> 

                          <h6>No item found</h6>
                          
                          </div>}

                        </tbody>
                        </table>
                    </div>
                    </>}

                                <div className="modal fade" id="modal3" tabindex="-1" role="dialog"
                                        aria-labelledby="modalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="modalLabel">Update Product</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-2"></div>
                                        <div className="col-8">
                                            <div className="row">
                                            <form class="col-12"  onSubmit={updateProd} method="POST" enctype="multipart/form-data" id="submit" >
                                                <div class="form-group">
                                                <label for="exampleFormControlFile1">Upload Item Image</label>
                                                <input type="file" class="form-control-file" name="productImage"
                                                onChange={(e)=> setProductImage(e.target.files[0])}/>
                                                </div>
                                                <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD PRODUCT</>)}</button>
                                            </form>
                                            
                                            </div>
                                        </div>
                                        <div className="col-2"></div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>


                                </div>
                            </div>
                </div>
            
  
          </Layout>
      

  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>


       
        <div className="row">
            <div className="col-1"></div>
            <div className="col-10">


                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row">
                        <form class="col-12"  onSubmit={addProd} enctype="multipart/form-data" id="submit" >
                           {msg !== "" &&
                            <p className="small alert alert-danger">{msg}</p>
                            }
                            <div class="form-group">
                            <label for="exampleFormControlFile1">Upload Item Image</label>
                            <input type="file" class="form-control-file" name="productImage"  onChange={(e)=> setProductImage(e.target.files[0])}/>
                            </div>
                        
                            <div class="form-group">
                            <input type="text" class="form-control" aria-describedby="textHelp" placeholder="Name" name="name"  value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div class="form-group">
                            <input type="number" class="form-control" aria-describedby="textHelp" placeholder="Price" name="price"  value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </div>
                            <div class="form-group">
                                <label for="formControlTextarea1">Description</label>
                                <textarea class="form-control" id="formControlTextarea1" rows="3" name="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Select Item Category</label>
                                <select class="form-control"  name="categoryId"  onChange={(e)=> setCategoryId(e.target.value)}>
                                    <option selected>Choose Product Category</option>
                                    {cat.map(c => (
                                          <option value={c._id}>{c.name}</option>
                                        ))}
                                </select>
                            </div>
                            <button type="submit" name="add" class="btn btn-warn btn-block btn-sm" disabled={isBtnLoading}>{isBtnLoading ? (<>LOADING</>) : (<>ADD PRODUCT</>)}</button>
                        </form>
                        
                        </div>
                    </div>
                    <div className="col-2"></div>
                 </div>
                
            </div>
            <div className="col-1"></div>
         </div>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

            
        </>
    );
};

export default Products;