import React, {useState, useEffect}  from 'react';
import configData from "../../config.json";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import {NumericFormat} from 'react-number-format';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Layout from '../Layout';


function VendorCheckout() {
    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    let { id} = useParams();

    const [disableBtn, setDisableBtn] = useState(false);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [address, setAddress] = useState('');
    const [itemSum, setItemSum] = useState();
    const [amount, setAmount] = useState();
    const [agentFee, setAgentFee] = useState();
    const [vendorFee, setVendorFee] = useState();
    const [balance, setBalance] = useState();
    const [ref, setRef] = useState();
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);
    const [disablebtn, setDisablebtn] = useState(false);
    const [load, setLoad] = useState(true);
    const email = localStorage.getItem('email');
    const phone = localStorage.getItem('phone');
    const fullname = localStorage.getItem('fullname')
    const [transfer, setTransfer] = useState(false);

  const getState = () => {
       
    return fetch(`${configData.SERVER_URL}/state`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setCity(responseJson.state);
            setLoad(false)
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getAreas = ()=> {
       
    return fetch(`${configData.SERVER_URL}/area`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setArea(responseJson.area);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const userBalance  =  localStorage.getItem('balance');

const states = async () => {
    try{
      const token = await localStorage.getItem('token');
      const ItemSum = await localStorage.getItem('itemSum'); 
      const vendorFee = await localStorage.getItem('vendorFee');
      const agentFee = await localStorage.getItem('agentFee');
      const amount = await localStorage.getItem('amount');
      const reference  = await localStorage.getItem('reference');
      const userBalance  = await localStorage.getItem('balance');
      setRef(JSON.parse(reference))
      setItemSum(JSON.parse(ItemSum))
      setVendorFee(JSON.parse(vendorFee))
      setAgentFee(JSON.parse(agentFee))
      setAmount(JSON.parse(amount))
      setToken(JSON.parse(token))
      setBalance(JSON.parse(userBalance))
     }    
    catch (e) {
      console.log(e, `=====error=====`);
    }
    
  }
 
  useEffect(() => {
    states();
    getState();
    getAreas();
 },[]);

 const [msg, setMsg] = useState([]);

 const pay = () =>{
    
    if(selectedArea === ""){
        setMsg("Please Select Area");
        setShow(true)
        return
    }
    if(selectedState === ""){
        setMsg('Please Select State');
        setShow(true)
        return
    }
    if(address === ""){
        setMsg('Please add your address');
        setShow(true)
        return
    }
    window.$(".success-modal-lg").modal("show");
}

const cont = () => {
  if(selectedArea === ""){
    setMsg("Please Select Area");
    setShow(true)
      return
  }
  if(selectedState === ""){
    setMsg("Please Select State");
    setShow(true);
      return
  }
  if(address === ""){
    setMsg("Please add your address");
    setShow(true);
      return
  }
  setDisablebtn(true);

  
  return  fetch(`${configData.SERVER_URL}/transaction/mortar/cartBalance/trans/${id}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      "x-auth-token": window.localStorage.getItem("token") 
       },
       body: JSON.stringify({
         state:selectedState,
         area:selectedArea,
         address:address,
         amount:amount,
         reference:ref
       }) 
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson)
      if (responseJson.status === "success") {
          const balance = JSON.stringify(responseJson.user.balance);
          localStorage.setItem("balance", balance); 
          localStorage.removeItem("itemSum");
          localStorage.removeItem("vendorFee");
          localStorage.removeItem("agentFee");
          localStorage.removeItem("amount");
          localStorage.removeItem("reference");
          setDisablebtn(false)
          navigate("/Order");
          
       } 
      if (responseJson.status === "error") {
        console.log(responseJson.message)
        setDisablebtn(false)
          alert(responseJson.message);
        }
    })
    .catch((error) => {
        console.error(error);
    })

}

const payWithCash = async() => {
  
    return  fetch(`${configData.SERVER_URL}/transaction/mortar/${id}/cashPayment`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
         },
         body: JSON.stringify({
           state:selectedState,
           area:selectedArea,
           address:address,
           amount:amount,
           reference:ref
         }) 
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        if (responseJson.status === "success") {
            const balance = JSON.stringify(responseJson.user.balance);
            localStorage.setItem("balance", balance);
            localStorage.removeItem("itemSum")
            localStorage.removeItem("vendorFee")
            localStorage.removeItem("agentFee")
            localStorage.removeItem("amount")
            localStorage.removeItem("reference")
            alert(responseJson.message);
            window.location = window.location.origin + "/Order" 
         } 
        if (responseJson.status === "error") {
          console.log(responseJson.message)
            alert(responseJson.message);
          }
      })
      .catch((error) => {
          console.error(error);
      })
  }

  const config = {
    public_key: `${configData.KEY}`,
    tx_ref: ref,
    amount: amount,
    currency: 'NGN',
    payment_options: 'card, ussd',
    customer: {
      email: email,
      phonenumber: phone,
      name: fullname ,
    },
    customizations: {
      title: 'IGOBI',
      description: 'Payment for items in cart',
      logo: '../img/logo.png',
    },
  };
  
  const handleFlutterPayment = useFlutterwave(config);
       

return (
   

 <Layout>
   
<section className="boxN2 border">



{loading ? <>
            <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>
            </div> 
     
     
     </> : <>

       <div className="topNav">
                    <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-4">
                                <span  onClick={() => navigate(-1)}  class="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </span>
                            </div>
                            <div className="col-4">
                                <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Checkout</h4>
                            </div>
                            <div className="col-4">
                                <img src="/img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                            </div>
                        </div>
                    </div>
                </div>


    
   <ToastContainer position="middle-start" className="p-3">
     <Toast bg={'danger'}  className={'text-white'} onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>{msg}</Toast.Body>
        </Toast>
    </ToastContainer>


        {load ? (<> 


            <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                    <span >Loading...</span>
                </div>
            </div> </> ) :  <>

   <div className="scroll">
            <section className="container mt-5">
                <div className="row mb-5">

                <div className="col-md-12">
                    <div className="row">

                    <div className="col-1"></div>
                     <div className="col-10 cad mt-2 border">
                        <div className="row">
                            <div className="col-2"></div>
                                <div class="col-md-8 mt-3">    

                                <form enctype="multipart/form-data" id="submit" style={{backgroundColor:"#fff"}}>

                                    <p class="mb-4" style={{fontWeight:'bold'}}>Please Complete the form</p> 

                                    <div class="form-group">
                                    <label for="exampleForm2">Select State</label>

                                    <select class="browser-default custom-select" name="selectedState" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                        <option selected>Choose State here</option>
                                        {city.map(c => (
                                            <option value={c._id}>{c.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div class="form-group">
                                    <label for="exampleForm2">Select your Area</label>
                                    <select class="browser-default custom-select"  name="selectedArea" value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)}>
                                        <option selected>Choose Area here</option>
                                        {area.map(a => (
                                            <option value={a._id}>{a.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea2">Complete Address</label>
                                        <textarea class="form-control rounded-0" id="exampleFormControlTextarea2" rows="3" value={address} onChange={(e) => setAddress(e.target.value)}/>
                                    </div>

                                    </form>
                                
                                </div>
                            <div className="col-2"></div>
                        </div>
                     </div>
                    <div className="col-1"></div>

            
                    </div>
                </div>

                </div>
            </section>



            <section className="container" >
                    <div className="row">

                        <div className="col-1"></div>
                        <div className="col-10 cad border">
                                <p class="mb-4"  style={{fontWeight:'bold'}}>Amount Summary</p> 
                                
                                <div className="mb-3" style={{width:'100%', justifyContent:'space-between'}}>
                                        <span className='p1'>Items Total</span>
                                        <NumericFormat value={itemSum} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />     
                                </div>
                                {/* <div className="mb-3" style={{width:'100%', justifyContent:'space-between'}}>
                                        <span className='p1'>5% Service Fee: </span>
                                        <NumericFormat value={vendorFee} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />
                                </div> */}
                                <div className="mb-3" style={{width:'100%', justifyContent:'space-between'}}>
                                        <span className='p1'>Service Fee: </span>
                                        <NumericFormat value={agentFee} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />
                                </div>
                                
                    {(userBalance <= 0) ? (null) : (<>  
                                <div className="mb-3" style={{width:'100%', justifyContent:'space-between'}}>
                                        <hr style={{marginTop:'2%'}}/>
                                        <span className='p1'> Total </span>
                                        <NumericFormat value={itemSum + agentFee} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />
                                        {/* <span className='p2'>₦ {itemSum + vendorFee + agentFee}</span> */}
                                </div>
                                <div className="" style={{width:'100%', justifyContent:'space-between'}}>
                                        <span className='p1'> Current balance </span>
                                        <NumericFormat value={userBalance} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />
                                        <hr style={{marginTop:'10%'}}/>
                                </div>

                        </>)}
                            {(userBalance > (itemSum + vendorFee + agentFee)) ? 
                            <> 
                            <span className="text-center" style={{fontSize:'14px', fontWeight:'bold', color:'#147439'}}> Your balance covers for your order click continue to complete transaction</span>
                            </> :
                                <div className="mb-3" style={{width:'100%', justifyContent:'space-between'}}>
                                        <span className='p1'> Amount to pay </span>
                                        <NumericFormat value={(itemSum + vendorFee + agentFee) - (userBalance)} displayType={'text'} thousandSeparator={true} prefix={'₦'}  className="ml-4 p2" />
                                </div>
                                 }
                        </div>
                        <div className="col-1"></div>
                    </div>
            </section> 
    </div>


        <section className="container mt-5" >
                <div className="row mb-5">

                <div className="checkout"></div>

                    <div className="cad bottomCheck">
                            {/* <NumericFormat value={cartTotal} displayType={'text'} thousandSeparator={true} prefix={'TOTAL: ₦'}  className="ml-4 p2Cart" /> */}
                            {(userBalance >= (itemSum + vendorFee + agentFee)) ? <>
                                        <div className="col-12">
                                            <button  onClick={()=> cont()} type="button" class="btn btn-md btn-block" style={{backgroundColor:'#27ae60', color:'#fff', borderRadius:22}}>{disablebtn ? (<>Please wait</>) : (<>CONTINUE</>)}</button>
                                        </div>
                                </>:<>
                                       <div className="col-6" style={{width:'100%', justifyContent:'space-between'}}>
                                                    <span  style={{fontWeight:'bold'}}> Amount to pay </span>
                                                   <NumericFormat value={(itemSum + vendorFee + agentFee) - (userBalance)} displayType={'text'} thousandSeparator={true} prefix={'₦'}  style={{fontWeight:'bold'}}/>
                                            </div>
                                        <div className="col-6">
                                            <button  onClick={()=> pay()} type="button" class="btn btn-md btn-block" style={{backgroundColor:'#27ae60', color:'#fff', borderRadius:22}}>PAY NOW</button>
                                        </div>
                                </>}

                    </div>

                <div className="checkout"></div>
                </div>
        </section> 
                        

        {/* Successfull Modal */}
        <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
      <div class="modal-dialog bottom modal-lg">  
        <div class="modal-content">
           <div class="modal-header">
            <p class="" id="myModalLabel"></p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
           </div>
                        <div class="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 ml-lg-3">
                              
                            {transfer ? <>
                              <div style={{height:"80%", paddingBottom:"40px", justifyContent:"center", alignItems: 'center'}}>
                                <p>Pay to the account below</p>
                                <NumericFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'₦ '} renderText={text => <p style={{marginBottom:10}}>{text}</p>}/>

                                <span>Bank Name: United Bank for Africa (UBA)</span><br/>
                                <span>Account Name: IGOBI MOBILE LTD</span>
                                <span>Account number: 1024179499</span>

                                <button onClick={()=> payWithCash()} type="submit" class="btn btn-warn my-2 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>I have made the Transfer</>)}</button>

                              </div>
                                    </> : <>
                                    <div class="col-md-12 mt-2">
                                       <p style={{fontSize:20}}>Select Payment method</p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <button type="submit" class="btn btn-warn my-2 btn-block waves-effect waves-light" disabled={disableBtn} 
      onClick={() => {
        handleFlutterPayment({
          callback: (response) => {
            console.log(response);

            if(response.status === "successful"){
              setLoading(true);
              closePaymentModal() 
              console.log(id)


              const transId = response.transaction_id;
              const reference = response.tx_ref;
              const amount = response.amount;
                                            
              return  fetch(`${configData.SERVER_URL}/transaction/mortar/${id}/${transId}/verify`, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "content-type": "application/json",
                  "x-auth-token": window.localStorage.getItem("token") 
                  },
                  body: JSON.stringify({
                    state:selectedState,
                    area:selectedArea,
                    address:address,
                    reference: reference
                  }) 
                })
                .then((response) => response.json())
                .then((responseJson) => {
                  console.log(responseJson)
                  if (responseJson.status === "success") {
                    const balance = JSON.stringify(responseJson.user.balance);
                    localStorage.setItem("balance", balance);
                    localStorage.removeItem("itemSum")
                    localStorage.removeItem("vendorFee")
                    localStorage.removeItem("agentFee")
                    localStorage.removeItem("amount")
                    localStorage.removeItem("reference")
                       alert(responseJson.message)
                       setLoading(false);
                       window.location = window.location.origin + "/Order" 
                     }
                  if (responseJson.status === "error") {
                      alert(responseJson.message);
                      closePaymentModal() 
                    }
                })
                .catch((error) => {
                    console.error(error);
                })  

            }

          },
          onClose: () => {},
        });
      }}>
                                        {disableBtn ? (<>Please wait</>) : (<>PAY WITH CARD</>)}
                                        </button>

                                        <button onClick={() => setTransfer(true)} type="submit" class="btn btn-white my-4  btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>PAY WITH TRANSFER</>)}</button>
                                    </div>
                                    
                                    </>}

                            
                           </div>
                            <div className="col-md-2"></div>
                        </div>
        </div>
      </div>
    </div>

      </>}

    </>}


</section>

 </Layout>


    );
}

export default VendorCheckout;