import React, {useEffect, useState} from 'react';
import Bar from "../component/Bar";
import Footer from "../component/Footer";
import configData from "../config.json";
import { useForm } from "react-hook-form";

const Register = () => {

        const { register, handleSubmit, watch, errors } = useForm();
        const [disablebtn, setDisablebtn] = useState(false);
        const [message, setMessage] = useState(false);
    
    
      const userReg = (data) => {
    
        setDisablebtn(true);
    
    
        return fetch(`${configData.SERVER_URL}/users/register`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fullname:data.fullname,
                email:data.email,
                phone:data.phone,
                password:data.password            
            })
         })
         .then((response) => response.json())
         .then((responseJson) => {
           console.log(responseJson)
           setDisablebtn(false);
             if(responseJson.status === "success"){

                window.localStorage.setItem("token", responseJson.token);
                window.localStorage.setItem("userId", responseJson.user._id);
                window.localStorage.setItem("fullname", responseJson.user.fullname);
                window.localStorage.setItem("email", responseJson.user.email);
                window.localStorage.setItem("phone", responseJson.user.phone);
                window.localStorage.setItem("balance", responseJson.user.balance);
                window.location = window.location.origin + "/";
             }
             if (responseJson.status === "error") {
                setDisablebtn(false);
                alert(responseJson.message);
                 setMessage(responseJson.message);
             }
         })
         .catch((error) => {
             console.log(error);
         });
    }
    

    
    return (


    <div style={{backgroundColor:"#fff"}}> 


<div className="topNav">
                    <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-4">
                                <div class="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </div>
                            </div>
                            <div className="col-4">
                                <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Register</h4>
                            </div>
                            <div className="col-4">
                                <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                            </div>
                        </div>
                    </div>
                </div>

        <div class="col-md-12"> 
         <div class="row">
             <div class="col-md-3">               
             </div>
             <div class="col-md-6 mt-5 mb-5">
                            <form  onSubmit={handleSubmit(userReg)} enctype="multipart/form-data" id="submit" class=" p-4">

                                            <p class="text-center h4 mb-4">User Registration</p>
                                        

                                            <div class="form-row">
                                                <div class="col-12 mt-2">
                                                    <input type="text" id="defaultRegisterFormFullName" class="form-control" placeholder="Full Name" name="fullname" ref={register({ required: true, })} />
                                                    {errors.fullname && <div class="alert alert-danger" role="alert">First Name Required</div>}
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="col-12 mt-2">
                                                    <input type="email" id="defaultRegisterFormEmail" class="form-control mb-4" placeholder="E-mail" name="email" ref={register({ required: true, })} />
                                                    {errors.email && <div class="alert alert-danger" role="alert">Email Required</div>}
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <input type="text" id="defaultRegisterPhonePassword" class="form-control" placeholder="Phone number" name="phone"  ref={register({ required: true, })} />
                                                    {errors.phone && <div class="alert alert-danger" role="alert">Phone Number Required</div>}
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <input type="password" id="defaultRegisterFormFullName" class="form-control mb-4" placeholder="Password" name="password" ref={register({ required: true, })} />
                                                    {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                                                </div>
                                             </div>                                         
                                         
                                             <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light">
                                              {disablebtn ? (<>Please wait</>) : (<>Register</>)}
                                            </button>
                                        

                                            <p class="text-center m-2">By clicking
                                                <em> Register</em> you agree to our
                                                <a href="" target="_blank"> terms of service</a>
                                             </p>

                                        </form>
                                    

             </div>
             <div class="col-md-3">               
             </div>
          </div> 
         </div>


        </div>




    );

}

export default Register;