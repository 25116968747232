import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import Layout from './Layout';

function HelpDesk () {


    let navigate = useNavigate();


    return (
         

 <Layout>

   
       <section className="boxN2 border">


            <div className="topNav">
                        <div className="col-md-12 mt-2">
                            <div className="row">
                                <div className="col-3">
                                    <Link to='/Profile' class="thmb-img" style={{cursor:"pointer"}}>
                                        <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                    </Link>
                                </div>
                                <div className="col-5">
                                    <h4  style={{color: '#000', marginTop: '10px', fontSize: '17px', textAlign:'center', fontWeight:'bold'}}>Help Desk</h4>
                                </div>
                                <div className="col-4">
                                    <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                                </div>
                            </div>
                        </div>
                    </div>


   <div className="scroll">
            <section className="container mt-5">
                <div className="row">

                <div className="col-1"></div>
                <div className="col-12">

                    <h4 style={{fontWeight:'bold', marginBottom:'30px'}}>Frequently Asked Questions?</h4>

                    <div id="accordion">
                        <div class="cad">
                            <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Q1. How does this work? 
                                </button>
                            </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                            We deliver to our Customers from anywhere. Our customers who are in the city of Port Harcourt, Rivers State (for now) presently, till we conclude with all other arrangements in other 17 states of Nigeria. We take your order by internet and our mobile applications, on IOS store and play store. We dispatch your order through our Alpha Agents closest to you with our special carrier vehicles depending on the quantity of your order.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Q2. How can I place my order ?
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                            You can place your order online at www.energieng.com or with our free android and IOS App to download.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Q3. Do you have a delivery fee ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                            Yes our delivery fee varies depending on the quantity of your order. The price will be shown when you make your order but the delivery fee is limited to your order quantity.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Q4. Are prices different at the vendor's ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                            <div class="card-body">
                            We do have services fee of N300.00 for bicycles vehicle delivery and N2,000.00 for car vehicle delivery.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingFive">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Q5. What are Partners ?
                                </button>
                            </h5>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                            <div class="card-body">
                            Our vendors partners are those who make it happen for us, making sure that all items and markers are secured to boost sells and supply of the right quality and quantity of goods and services to the customers. We are working to get as many customers as possible to help elevate the standard of living in our communities.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingSix">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Q6. What if the vendor partner has changed its price ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                            <div class="card-body">
                            If there has been a regulatory change of price and there is a difference of more than what you paid at any of your purchases,  We will contact you to make a reconciliation to make sure the price difference is ok.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingSeven">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Q7. How long does it take for a delivery ?
                                </button>
                            </h5>
                            </div>
                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                            <div class="card-body">
                            Our structure is to have to closest Alpha Agent living in your neighbourhood do the delivery from the closets vendor in your neighbourhood as well doing the supply. All Alpha Agents dedicated and focused to receive your orders and effect the delivery in the shortest of time or as fast as possible. 
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingEight">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Q8. How can I put special request on my online order ?  
                                </button>
                            </h5>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                            <div class="card-body">
                            Whenever you have a special request, make a schedule on our applications, it will be followed accordingly. 
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingNine">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Q9. Can I order for another person in/outside my neighbourhood ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                            <div class="card-body">
                            Yes add addresses and details of the person receiving the order and inform the person of the transaction. Our Alpha Agent living in that neighbourhood will do the delivery to that person.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingTen">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Q10. Who do I call if there is a mistake with my order ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                            <div class="card-body">
                            Please call the customer care via our applications on our different online options or dedicated phone numbers and they will effect the required change you asap.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingEleven">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Q11. What happens if I am missing items from my order when it is delivered ? 
                                </button>
                            </h5>
                            </div>
                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                            <div class="card-body">
                            Call us within one hour of delivery so we may contact the vendors about the shortage . Any credits will be based upon the vendors decision. Our Alpha Agents do not open up containers to verify your order. We rely on the vendor who is packaging your order to pack it correctly. If the vendor decides to offer a credit, then we apply a credith to your account to be used on your next order from any vendor. 
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingTwelve">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                Q12. What is your refund policy ?
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                            <div class="card-body">
                            The customer is financially responsible for payment once an order is submitted. If you want to change your order, we will attempt to accommodate such wishes within the time constraints and the goods will from the participating vendor. However if a change is too late to process you are responsible for payment of the original order. Our structure is such that transactions are done very quickly / automatically.
                            </div>
                            </div>
                        </div>
                        <div class="cad">
                            <div class="card-header" id="headingThirteen">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                Q13. About online card usage ?
                                </button>
                            </h5>
                            </div>
                            <div id="collapseThirteen" class="collapse" aria-labelledby="headingThirteen" data-parent="#accordion">
                            <div class="card-body">
                            Your card is charged online through flutterwave gateway. Sometimes the vendors make prices change on items. If such situation happens we will inform you about the difference of price and we will request your authorization to apply the amount to your credith card on file. Occasionally there are times your card maybe charged manually which is why the information is entered online. If you are placing an order for someone else , please in for us of the zip code associated with the entered card so that it can be charged manually.  
                            </div>
                            </div>
                        </div>
                       
                        </div>
                   
                    </div>

                    <div className="col-1"></div>
                    </div>
             </section>
    </div>



            </section>

</Layout>





    );
}

export default HelpDesk;