
import React, {useState, useEffect, useContext} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import configData from "../../config.json";
import { useForm } from "react-hook-form";
import Layout from '../Layout';
import {NumericFormat} from 'react-number-format';

function VendorCart() {
    const location = useLocation();
    const navigate = useNavigate();
    let { id} = useParams();
    const { register, handleSubmit, watch, errors } = useForm();
    const [disableBtn, setDisableBtn] = useState(false);
    const [product, setProduct] = useState([]);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [amount, setAmount] = useState();
    const [cartTotal, setCartTotal] = useState([]);
    const [numbCart, setNumbCart] = useState();
    const [load, setLoad] = useState(true);
    const [item, setItem] = useState('');
    const [cartItem, setCartItem] = useState([]);
    const [loading, setLoading] = useState(false);

    const {state} = useLocation();




      const getCart = async() => {
          
        return fetch(`${configData.SERVER_URL}/vendorCart/userCart/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token") 
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
            setProduct(responseJson.cart);
            setNumbCart(responseJson.cart.length);
            setCartTotal(responseJson.cartTotal);
            setLoad(false)
          })
          .catch((error) => {
            console.error(error);
          });
      }


    useEffect(() => {
        getCart(); 
        const interval=setInterval(()=> {
          getCart()
        },3000)
          
        return()=>clearInterval(interval)
      },[])


    const updateCart = async(data) => {

      const userId = await localStorage.getItem('userId');

      const prodId = data.productId;
      
      setDisableBtn(true);

      return fetch(`${configData.SERVER_URL}/vendorCart/addUpdate/${prodId}`, {
          method: "post",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token": window.localStorage.getItem("token") 
          },
          body: JSON.stringify({         
              amount:data.amount,
              userId:userId
          })
      })
      .then((response) => response.json())
      .then((responseJson) => {

          if(responseJson.status === "success"){
            setDisableBtn(false)
            alert(responseJson.message);
            window.$("#cartModal").modal("hide") 
          }
          if (responseJson.status === "error") {
            setDisableBtn(false)
              alert("yea " + responseJson.message);
          }
      })
      .catch((error) => {
        setDisableBtn(false)
          console.error(error);
      });
    }

    const subProd = async(data) => {
      setDisableBtn(true)
      
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const prodId = data.productId;;
      
      return fetch(`${configData.SERVER_URL}/vendorCart/subUpdate/${prodId}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": window.localStorage.getItem("token") 
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDisableBtn(false)
          alert(responseJson.message);
          window.$("#cartModal").modal("hide");
        })
        .catch((error) => {
          setDisableBtn(false)
          console.error(error);
        });
    }
        
    const transaction = async () => {

        setDisableBtn(true);    
                      
        if (cartTotal === '') 
          {
            setDisableBtn(false);
            alert('Empty Fields');
            return;
          }
        if (isNaN(cartTotal)) {
          setDisableBtn(false);
          alert('Amount Not a Number')
              return;
            }
        const type = "conv"
      
            return  fetch(`${configData.SERVER_URL}/transaction/${type}`, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "content-type": "application/json",
                  "x-auth-token": window.localStorage.getItem("token") 
                },
                body: JSON.stringify({
                  amount:cartTotal,
                }) 
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                  console.log(responseJson)
                  if (responseJson.status === "success") {
                    setDisableBtn(false);
      
                    const itemSum = JSON.stringify(responseJson.totalAmount);
                    const vendorFee = JSON.stringify(responseJson.vendorFee);
                    const agentFee = JSON.stringify(responseJson.agentFee);
                    const reference = JSON.stringify(responseJson.transaction.reference);
                    const amount = JSON.stringify(responseJson.transaction.amountToPay);
                    const balance = JSON.stringify(responseJson.balance);
                    
                    try {
                          localStorage.setItem("itemSum", itemSum)
                          localStorage.setItem("vendorFee", vendorFee)
                          localStorage.setItem("agentFee", agentFee)
                          localStorage.setItem("amount", amount)
                          localStorage.setItem("reference", reference)
                          localStorage.setItem("balance", balance)

                          console.log("fgvhbjnkm " + id)

                          navigate(`/Store/Checkout/${id}`);
                          
                        } catch (e) {
                                    // saving error
                          }
                  } 
                  if (responseJson.status === "error") {
                    setDisableBtn(false);
                      alert(responseJson.message);
                    }
                })
                .catch((error) => {
                  setDisableBtn(false);
                    console.error(error);
                })
      }
      
  return (
   <>

<Layout>

         <section className="box2 border">


              <div>


                       <div className="topNav">
                                <div className="col-md-12">
                                  <div className="row">
                                      <div className="col-4">
                                      <a href="/Basket"  class="thmb-img">
                                          <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                      </a>
                                      </div>
                                      <div className="col-4">
                                          <h4  style={{color: '#000',  marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Basket</h4>
                                      </div>
                                      <div className="col-4">
                                          <img src="/img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                                      </div>
                                  </div>
                                  </div>
                          </div>

         {load ? (<> 

              <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
           </> ) :  <>

                <section className="container mt-5">
                  <div className="row mb-5">

                  <div className="col-md-12" style={{marginBottom:'10%'}}>

                        {product && product.length > 0
                              ? product.map(p => {
                                  return <>
                                  <div className="row">
                                      <div className="col-1"></div>
                                      <div className="col-10 pr-5 card mt-2">
                                          <div className="row h-100 justify-content-center align-items-center">
                                              <div className="col-3">
                                              <img className="img-fluid mx-auto d-block cart-img"  src={`${configData.PIC_URL}/${p.productImage}`}/>
                                              </div>
                                              <div className="col-7">
                                                  <span>{p.productName} <br/></span>
                                                  <NumericFormat value={p.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'}/>
                                              </div>
                                              <div className="col-2">
                                                  <div className="row">
                                                      <div className="col-12">
                                                          <button onClick={()=> updateCart(p)} className="btn add">+</button>
                                                      </div>
                                                      <div className="col-12">
                                                          <button onClick={()=> subProd(p)} className="btn sub" disabled={disableBtn}>-</button>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-1"></div>
                                  </div>
                  
                            </> })
                                      :  <section className="container mt-5">
                                          <div className="row justify-content-center align-items-center">
                                                  <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Item In Basket</p>
                                              </div>
                                          </section>}
                
                    </div>

                  </div>
                </section>



                {numbCart > 0 ?

                


             <section className="container mt-5" >
                      <div className="row mb-5">

                        <div className="checkout"></div>

                          <div className="cad bottomCheckTab">
                                  <div>
                                      <NumericFormat value={cartTotal} displayType={'text'} thousandSeparator={true} prefix={'TOTAL: ₦'}  style={{fontWeight:'bold'}} />
                                  </div>
                                  <button onClick={() => transaction()}  type="button" class="btn btn-md" style={{backgroundColor:'#27ae60', color:'#fff', borderRadius:22}}>Buy</button>
                           </div>

                        <div className="checkout"></div>
                      </div>
                </section> : null}
                            


                                  
                <div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <form onSubmit={handleSubmit(updateCart)}  enctype="multipart/form-data" class="" style={{paddingTop:"20px", paddingLeft:"20px", paddingRight:"20px"}}>
                              
                                  <h5 style={{color:"#000"}}>How Much {productName} do you want to buy</h5>

                                  <div class="form-group">
                                      <input type="text" class="form-control mt-4" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Amount (NGN)" name="amount" value={amount} ref={register({ required: true, })} />
                                      {errors.amount && <small id="emailHelp" class="form-text text-muted">Please add amount.</small>}
                                  </div>
                                  {/* <div class="col-md-12"> */}
                                      <button type="submit" class="btn btn-warn btn-block" name="submit" disabled={disableBtn}>
                                      {(disableBtn) ? ( <>Please wait </>) : (<>Add To Basket </>)}</button>
                                  {/* </div> */}
                              </form>
                              
                            </div>
                          </div>
                        </div>
                      </div>

                        
                </> }
    



              </div>


          </section>

   </Layout>


   </>
  );
}

export default VendorCart;
