import React, {useState, useEffect, useContext} from 'react';
// import {Redirect, Link, useParams} from "react-router-dom";
import configData from "../../config.json";
import { useForm } from "react-hook-form";
import Button from "../../component/Button";
// import Login from "../Login";
import Layout from '../Layout';

function MarketTab() {
    const { register, handleSubmit, watch, errors } = useForm();
    const [disableBtn, setDisableBtn] = useState(false);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [amount, setAmount] = useState();
    // const [state, setState] = useContext(Context);
    const [load, setLoad] = useState(true);
    const [inputText, setInputText] = useState('')
    const [activeBtn, setActiveBtn] = useState('');


  const [cat, setCat] = useState([])
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProd = () => {
    
    return fetch(`${configData.SERVER_URL}/product`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
          setLoad(false)
          setProduct(responseJson.product);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getCat = () => {
      
    return fetch(`${configData.SERVER_URL}/category`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
          setCat(responseJson.category);
      })
      .catch((error) => {
        console.error(error);
      });
  }

useEffect(() => {
    getProd();
    getCat();
  },[]);
  
const gobasket = () => {
  window.$(".success-modal-lg").modal("hide");
  window.location = window.location.origin + "/Basket";
}

  const cartModal = (id, item) => {

    const userId = localStorage.getItem('userId');

    if(!userId){
         window.$(".login").modal("show") 
         return
    }
    setProductId(id);
    setProductName(item);
    window.$("#cartModal").modal("show") 
 }

  const addToCart = async(productId) => {
     
    if(amount === ''){
        alert('Product amount is required');
        return ;
    }
    if(amount <= 0){
        alert('Invalid Amount');
        return;
    }
    if(isNaN(amount)){
        alert('Product amount is not a number');
        return;
    }
  const token = await localStorage.getItem('token');
  const userId = await localStorage.getItem('userId');
  
    const prodId = productId;
      
    setDisableBtn(true)

    return fetch(`${configData.SERVER_URL}/cart/${prodId}`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token") 
        },
        body: JSON.stringify({         
            amount:amount,
            userId:userId
        })
    })
    .then((response) => response.json())
    .then((responseJson) => {

        if(responseJson.status === "success"){
            setDisableBtn(false);
            setAmount();
            window.$("#cartModal").modal("hide");
            window.$(".success-modal-lg").modal("show");
        }
        if (responseJson.status === "error") {
            setDisableBtn(false)
            alert(responseJson.message);
        }
    })
    .catch((error) => {
      setDisableBtn(false)
        console.error(error);
    });

  }

  const shop = () => {
    window.$(".success-modal-lg").modal("hide");
  }


const subcat = (searchText) => {

    setIsLoading(true);
  return fetch(`${configData.SERVER_URL}/product/productCat`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": window.localStorage.getItem("token") 
    },
    body: JSON.stringify({
      inputText:searchText,
    }) 
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson.product)
      setProduct(responseJson.product);
      if(searchText === ''){
        setActiveBtn('');
        setIsLoading(false);
      }else{
        setActiveBtn(responseJson.product[0].catName);
        console.log(responseJson.product[0].catName);
        setIsLoading(false);
      }
    })
    .catch((error) => { 
      console.error(error);
    });
}

const SearchProd = () => {
  return fetch(`${configData.SERVER_URL}/product/search`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": window.localStorage.getItem("token") 
    },
    body: JSON.stringify({
      inputText:inputText,
    }) 
  })
    .then((response) => response.json())
    .then((responseJson) => {
      setProduct(responseJson.product);
    })
    .catch((error) => { 
      console.error(error);
    });
}

const whatsapp = () => {
  window.location.href = "https://wa.me/+2348064080625/?text=urlencodedtext";
      
}


  return (
   <>
     
     <Layout>
         <section className="box2 border">


<div>


          <div className="topNav">
                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-4">
                                <a href="/"  className="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </a>
                        </div>
                        <div className="col-4">
                            <h4  style={{color: '#000', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Market</h4>
                        </div>
                        <div className="col-4">
                              <div onClick={() => gobasket()} style={{ width:"70%", height:"100%", display: 'flex', flexDirection: 'row', justifyContent:"space-around", alignItems: 'center', backgroundColor:'#147439', padding:"2% 3%", borderRadius:"20px", color:'#fff'}}>
                              <span>Cart</span> 
                              <i class="fas fa-shopping-basket d-flex justify-content-center" style={{fontSize: '1.2rem', textAlign:'center', color:'#fff'}}></i>
                              </div>
                        </div>
                    </div>
                    </div>
                </div>


            <div className="secOne">
                <div className="col-md-12">
                    <div className="row">
                        <div class="md-form col-10">
                            <input placeholder=" Search for food Items Here..." type="text" id="search" class="form-control form-control-sm"                          
                            onChange={(e) => setInputText(e.target.value)}
                            value={inputText}/>
                        </div>
                        <div class="md-form col-2">
                                  <span onClick={()=> SearchProd()} style={{cursor:'pointer'}}>
                                     <i class="fas fa-search" id="seac" style={{color:'#fff'}}></i>
                                  </span>
                          </div>
                    </div>
                </div>
                <div className="col-md-12">

                     <div class="scrollmenu pt-2 pb-2">
                        <a onClick={()=> subcat('')} style={(activeBtn === '')? {borderWidth:1, backgroundColor:'#147439', } : {borderWidth:1, borderColor:'#147439'}}>
                            <span style={(activeBtn === '')? { color:'#fff'} : {color:'#147439'}}>All</span>
                         </a>

                   {cat.map(cat => (<>
                        <a onClick={()=> subcat(cat.name)} style={(activeBtn === cat.name)? {borderWidth:1, backgroundColor:'#147439', } : {borderWidth:1, borderColor:'#147439'}}>
                            <span style={(activeBtn === cat.name)?  { color:'#fff'} : {color:'#147439'}}>{cat.name}</span>
                         </a>
                         </>))}

                        </div>

                 </div>

            </div>


            <div className="col-md-12">
                    <div className="row pr-3 pl-3">


                 {load ? (<> 

                        <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            <span >Loading...</span>
                        </div>
                        </div>

                        </>) 

                        : (<>
                                                                        
              {product && product.length > 0
                ? product.map(p => {
                return <>

                    <div className="col-6 transOne border">
                        <div className="row">
                              <div className="boxOne col-12">
                                  <img className="img-fluid mx-auto d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`}/>
                              </div>
                              <div className="boxTwo col-12">
                                    <div className="row">
                                            <div className="col-8 pl-4 pb-2 textDiv">
                                                  <span className='text'>{p.name}</span>
                                            </div>
                                            <a onClick={()=> cartModal(p._id, p.name)} className="col-4 borderCard">
                                                <span>
                                                <i class="fas fa-plus-circle" style={{fontSize: '1rem', alignSelf:'center', color:'#fff', marginTop:'0.5rem'}}></i>
                                                </span>
                                            </a>
                                   </div>
                              </div>
                          </div>
                        </div>
                            
                            </> })
                            :<section className="container mt-5">
                                <div className="row justify-content-center align-items-center">
                                        <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Item Found</p>
                                    </div>
                                </section>}

                                </>)}





                     </div>
                 </div>

                     <Button/>

                {/* add item to cart     */}
        <div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form enctype="multipart/form-data" class="" style={{paddingTop:"20px", paddingLeft:"20px", paddingRight:"20px"}}>
                
                    <h4 style={{color:"#000"}}>How Much {productName} do you want to buy</h4>

                    {/* <div class="form-group">
                        <input type="text" class="form-control mt-4" placeholder="Item Name"></input>
                        <small id="emailHelp" class="form-text text-muted">Please add Item Name.</small>
                    </div> */}

                    <div class="form-group">
                        <input type="text" class="form-control mt-4" id="exampleInputEmail1" name="amount" aria-describedby="emailHelp" placeholder="Amount (NGN)" value={amount} onChange={e => setAmount(e.target.value)} ref={register({ required: true, })} />
                        {errors.amount && <small id="emailHelp" class="form-text text-muted">Please add amount.</small>}
                     </div>
                    <div class="mb-4">
                        <button onClick={()=> addToCart(productId)} class="btn btn-warn btn-block" name="submit" disabled={disableBtn}>
                        {(disableBtn) ? ( <>Please wait </>) : (<>Add To Basket </>)}</button>
                    </div>


                    <div onClick={()=> whatsapp()} style={{width: '100%', textAlign:'center'}}>
                            <span style={{fontSize: '18px', fontWeight:'bold', color: '#147439', textAlign:'center'}}>need help?  
                                <i class="fa fa-whatsapp" style={{fontSize: '18px', color: '#147439'}}></i>
                            </span>
                        </div>

                </form>
                
              </div>
            </div>
          </div>
        </div>



          {/* Successfull Modal */}
    <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
      <div class="modal-dialog bottom modal-lg">  
        <div class="modal-content">
           <div class="modal-header">
            <p class="" id="myModalLabel">Successfull</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
           </div>
                        <div class="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 ml-lg-3">
                                   {/* <img src="./img/Success.png" width="100" class="d-inline-block align-top" id='success' alt="" /> */}
                                    <div class="col-md-12 mt-2">
                                       <p style={{fontSize:20}}>Item Added, continue to list your items until you are done, then click on the basket to purchase them</p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <button onClick={()=> gobasket()}  type="submit" class="btn btn-white my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>GO TO BASKET</>)}</button>

                                        <button onClick={()=> shop()} type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>CONTINUE SHOPPING</>)}</button>
                                    </div>
                           </div>
                            <div className="col-md-2"></div>
                        </div>
        </div>
      </div>
    </div>


       {/* Login modal */}
       <div class="modal fade login" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                <div class="modal-dialog bottom modal-lg">  
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="" id="myModalLabel"></p>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="row">
                            {/* <Login/> */}
                        </div>
                    </div>
                </div>
             </div>



</div>



         </section>

     </Layout>

   </>
  );
}

export default MarketTab;
