import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const logout = () => {
        window.localStorage.clear();
        window.location = window.location.origin + "/vendor";
    }
    return (
        <>


<div className="vSidebar">

    <div className=''>
        
    </div>

  
  <ul className="list-group list-group-flush">
        <li className="list-group-item">
            <Link to="/Dashboard">Dashboard</Link>
            </li>
        <li className="list-group-item">
           <Link to="/Product/Categories">Categories</Link> 
         </li>
        <li className="list-group-item">
            <Link to="/Products">Products</Link> 
        </li>
        <li className="list-group-item">
            <Link to="/Transactions">Transactions</Link> 
        </li>
        <li onClick={logout} className="list-group-item">Logout</li>
    </ul>


  </div>
            
        </>
    );
};

export default Sidebar;