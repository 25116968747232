import React, {useState, useEffect}  from 'react';
import configData from "../config.json";
import {useLocation, useNavigate} from "react-router-dom";
import Layout from './Layout';
function Deals() {
  const location = useLocation();
  const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);
    const [cat, setCat] = useState([])
    const [amount, setAmount] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState([])
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [load, setLoad] = useState(false)


    const cartModal = (id, item, amount) => {
        setProductId(id);
        setProductName(item);
        setAmount(amount);
        window.$(".success-modal-lg").modal("show") 
     }

      const getProd = () => {
        return fetch(`${configData.SERVER_URL}/deal/home`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
             "x-auth-token": window.localStorage.getItem("token") 
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
              setProduct(responseJson.product);
              setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    
      useEffect(() => {
        getProd();
     },[]);



     const transaction = async() => {

        const userId = localStorage.getItem('userId');
    
        if(!userId){
             window.$(".login").modal("show") 
             return
        }
    
        setDisableBtn(true);
            
                      
        if (amount === '') 
          {
            setDisableBtn(false);
            alert('Amount is Empty')
            return;
          }
        if (isNaN(amount)) {
          setDisableBtn(false);
                alert('Amount not a number')
              return;
            }
      
        const token =  localStorage.getItem('token');
       
      
            return  fetch(`${configData.SERVER_URL}/transaction/deal/${productId}`, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "content-type": "application/json",
                  "x-auth-token": token 
                },
                body: JSON.stringify({
                  amount:amount,
                }) 
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                  console.log(responseJson)
                  if (responseJson.status === "success") {
                    setDisableBtn(false);
      
                    const itemSum = JSON.stringify(responseJson.totalAmount);
                    const vendorFee = JSON.stringify(responseJson.vendorFee);
                    const agentFee = JSON.stringify(responseJson.agentFee);
                    const reference = JSON.stringify(responseJson.transaction.reference);
                    const amount = JSON.stringify(responseJson.transaction.amountToPay);
                    const prodId = JSON.stringify(productId);
                    
                    try {
                           localStorage.setItem("itemSum", itemSum)
                           localStorage.setItem("vendorFee", vendorFee)
                           localStorage.setItem("agentFee", agentFee)
                           localStorage.setItem("amount", amount)
                           localStorage.setItem("reference", reference)
                           localStorage.setItem("dealId", prodId)

                          window.$(".success-modal-lg").modal("hide") 
                           navigate(`/DealCheckout`, {state:{
                            data: productId
                        }});
                          //  window.location = window.location.origin + "/DealCheckout" 
                           
                        } catch (e) {
                                    // saving error
                          }
                   } 
                  if (responseJson.status === "error") {
                    setDisableBtn(false);
                      alert(responseJson.message);
                    }
                })
                .catch((error) => {
                  setDisableBtn(false);
                    console.error(error);
                })
      
        }
      
    


    return (
        <>


<Layout>
   
   
<section className="boxN2 border">


       <div className="topNav">
                    <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-4">
                                <a href="/"  class="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </a>
                            </div>
                            <div className="col-4">
                                <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Deals</h4>
                            </div>
                            <div className="col-4">
                                <img src="/img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                            </div>
                        </div>
                    </div>
                </div>


       
                <div className="col-md-12 mt-4">
                    <div className="row pr-3 pl-3">


                 {isLoading ? (<> 

                        <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                            <span >Loading...</span>
                        </div>
                        </div>

                        </>) 

                        : (<>
                                                                        
             {product && product.length > 0
                ? product.map(p => {
                    return <>
                    <div className="col-6 transOne mt-4 border">
                        <div className="row">
                              <div className="boxOne col-12">
                                  <img className="img-fluid mx-auto d-block prod-img"     src={`${configData.PIC_URL}/${p.productImage}`}/>
                              </div>
                              <div className="boxTwo col-12">
                                    <div className="row">
                                            <div className="col-8 pl-4 pb-2">
                                                    <span className='text'>{p.name}</span>
                                                    <span className="textAmount"> # {p.amount}</span>
                                            </div>
                                            <a onClick={()=> cartModal(p._id, p.name, p.amount)} className="col-3  pr-4 borderCard">
                                                    <img className="mx-auto d-block"  src='/img/iconThumb.png' style={{width:'40px' }}/>
                                            </a>
                                   </div>
                              </div>
                          </div>
                        </div>

                        </> })
                        :<section className="container mt-5">
                        <div className="row justify-content-center align-items-center">
                                <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Product Found</p>
                            </div>
                         </section>}

                                </>)}





                     </div>
                 </div>

                 


</section>


 </Layout>



       {/* Accept Deal modal */}
       <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                <div class="modal-dialog bottom modal-lg">  
                    <div class="modal-content">
                    <div class="modal-header">
                        <p class="" id="myModalLabel"></p>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                                    <div class="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8 ml-lg-3">
                                                <div class="col-md-12 mt-2">
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <button onClick={()=> transaction()} type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>
                                                    {disableBtn ? (<>Please wait</>) : (<>ACCEPT DEAL</>)}
                                                    </button>
                                                </div>
                                    </div>
                                        <div className="col-md-2"></div>
                                    </div>
                    </div>
                </div>
                </div>


            
        </>
    );
}

export default Deals;