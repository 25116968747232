import React, {useState, useEffect} from 'react';
import configData from "../../config.json";
import { useForm } from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";

function ProfileTab() {
    let navigate = useNavigate();
    const { register, handleSubmit, watch, errors } = useForm();
    const [disableBtn, setDisableBtn] = useState(false);

    const balance = localStorage.getItem('balance');
    const fullname = localStorage.getItem('fullname');
    const email = localStorage.getItem('email');
    const phone = localStorage.getItem('phone');


  const [trans, setTrans] = useState([]);

  const getTransaction = async() => {
    const userId  = await localStorage.getItem('userId');
    
    return fetch(`${configData.SERVER_URL}/transaction/user/${userId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        setTrans(responseJson.trans.length);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const interval=setInterval(()=>{
      getTransaction();
    },3000)
    
    return()=>clearInterval(interval)
},[]);

const whatsapp = () => {
    window.location.href = "https://wa.me/+2348064080625/?text=urlencodedtext";
    // history.push('https://wa.me/+2348064080625/?text=urlencodedtext')
        
  }


    const logout =() =>{
        localStorage.clear();
        window.location = window.location.origin + "/";
    }

  return (
   <>


<div>


<div className="topNav">
                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-4">
                                <div class="thmb-img" style={{cursor:"pointer"}}  onClick={() => navigate("/")}>
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </div>
                        </div>
                        <div className="col-4">
                            <h4  style={{color: '#000',  marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Profile</h4>
                        </div>
                        <div className="col-4">
                            <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                        </div>
                    </div>
                    </div>
                </div>



                <div class="container">
                    <div class="row mt-5  justify-content-center align-items-center">
                            {/* <img src="./img/user.png"  class="d-inline-block align-top  float-right" alt="mascout" width="80"/> */}
                        <div class="col-12 mt-4">
                                   <p style={{fontSize: '15px', color: '#000', marginBottom:'5px', fontWeight:'bold', textAlign:'center'}}>{fullname}</p>
                                   <p style={{fontSize: '12px', color: '#147439', fontWeight:'bold', textAlign:'center'}}>{phone}</p>
                        </div>
                        <div class="col-12 mt-2">
                            <div className="row">
                                <div class="col-1"></div>
                               <div class="col-4" style={{backgroundColor:'#ededed'}}>
                                   <span className="d-flex justify-content-center" style={{fontSize: '12px', color: '#147439',marginTop:'10px',  fontWeight:'bold', textAlign:'center'}}>NGN {balance}</span>
                                   <p style={{fontSize: '15px', color: '#147439', fontWeight:'bold', textAlign:'center'}}>Balance</p>
                                </div>
                              
                                <div class="col-2"></div>

                                <div class="col-4" style={{backgroundColor:'#ededed'}}>
                                   <span className="d-flex justify-content-center" style={{fontSize: '12px', color: '#147439',marginTop:'10px',  fontWeight:'bold', textAlign:'center'}}>{trans}</span>
                                   <p style={{fontSize: '15px', color: '#147439', fontWeight:'bold', textAlign:'center'}}>Order</p>
                                </div>
                                <div class="col-1"></div>
                            </div>
                        </div>
                    </div>
                 </div>


<div class="container">
    <div class="row justify-content-center align-items-center mt-4">
        <div class="col-4">
            <Link to="/HelpDesk">
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fas fa-question-circle" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    <h2 style={{fontSize: '12px', color: '#000'}}>Help Desk</h2>
                </div>
            </Link>
        </div>
        <div class="col-4">
            <Link to="/order">
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fas fa-motorcycle" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    <h2 style={{fontSize: '12px', color: '#000'}}>Orders</h2>
                </div>
            </Link>
        </div>
        <div class="col-4">
            <Link to="/EditProfile">
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fas fa-user-plus" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    <h2 style={{fontSize: '12px', color: '#000'}}>My Profile</h2>
                </div>
            </Link>
        </div>
        <div class="col-4">
            <Link  >
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fas fa-share-alt" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    
                    <h2 style={{fontSize: '12px', color: '#000'}}>Share</h2>
                </div>
            </Link>
        </div>
        <div class="col-4">
            <Link onClick={()=> whatsapp()}>
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fa fa-whatsapp" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    <h2 style={{fontSize: '12px', color: '#000'}}>Message</h2>
                </div>
            </Link>
        </div>
        <div class="col-4">
            <Link onClick={()=> logout()}>
                <div class="list-box">
                    <div class="thmb-img text-center">
                        <i class="fas fa-sign-out-alt" style={{fontSize: '25px', color: '#147439'}}></i>
                    </div>
                    <h2 style={{fontSize: '12px', color: '#000'}}>Logout</h2>
                </div>
            </Link>
        </div>
    </div>
</div>

</div>

</>
  );
}

export default ProfileTab;
