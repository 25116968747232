import React, {useState, useEffect} from 'react';
import configData from "../config.json";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Layout from './Layout';

function OrderDetails() {
    let navigate = useNavigate();
    const { register, handleSubmit, watch, errors } = useForm();
    const [trans, setTrans] = useState([]);
    const [transItem, setTransItem] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [load, setLoad] = useState(true);


    const refId  =  window.localStorage.getItem("refNumb");
    const id =   window.localStorage.getItem("tranId");

    const getTransDetails = () => {
        setIsLoading(true);
        return fetch(`${configData.SERVER_URL}/transaction/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // "x-auth-token": JSON.parse(AsyncStorage.getItem("token"))
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
            setTrans(responseJson.trans);
            setLoad(false);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      }
    
   const getTransItem = () => {
    setIsLoading(true);
    return fetch(`${configData.SERVER_URL}/transactionItem/${refId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "x-auth-token": JSON.parse(AsyncStorage.getItem("token"))
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        setTransItem(responseJson.transItem);
        setLoad(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

    useEffect(() => {
      const interval=setInterval(()=>{
        getTransDetails();
        getTransItem();
       },5000)
         
         
       return()=>clearInterval(interval)
    },[]);


 const recievedItem = async() => {
  
    const token = await localStorage.getItem('token');
    const userId = await localStorage.getItem('userId');
    
      const transId = id;

     
      setDisableBtn(true)
    
      return fetch(`${configData.SERVER_URL}/transaction/recievedCustomer/${transId}`, {
          method: "post",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token": token
          },
          body: JSON.stringify({  
              userId:userId
          })
      })
      .then((response) => response.json())
      .then((responseJson) => {
    
          if(responseJson.status === "success"){
              setDisableBtn(false)
              alert(responseJson.message)
          }
          if (responseJson.status === "error") {
            setDisableBtn(false)
              alert(responseJson.message);
          }
      })
      .catch((error) => {
        setDisableBtn(false)
          console.error(error);
      });
      
   }
  

  
  return (
   <>

<Layout>
   
<section className="boxN2 border">


<div className="topNav">
             <div className="col-md-12 mt-2">
                 <div className="row">
                     <div className="col-3">
                         <div class="thmb-img" style={{cursor:"pointer"}}  onClick={() => navigate(-1)}>
                             <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                         </div>
                     </div>
                     <div className="col-5">
                         <h4  style={{color: '#000',  marginTop: '10px', fontSize: '17px', textAlign:'center', fontWeight:'bold'}}>Order Details</h4>
                     </div>
                     <div className="col-4">
                         <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                     </div>
                 </div>
             </div>
         </div>

    {load ? (<> 


<div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
</div> </> ) : <>

  <section className="container mt-5">
    <div className="row mb-5">

     <div className="col-md-12" style={{marginBottom:'10%'}}>
          <div className="row">

          {transItem && transItem.length > 0
                ? transItem.map(p => {
                    return <>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 pt-3 pb-3 cad mt-2  border-bottom">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-4">
                           <img className="img-fluid mx-auto d-block prod-img"  src={`${configData.PIC_URL}/${p.productImage}`}/>
                        </div>
                        <div className="col-6">
                            <span>{p.productName} <br/></span>
                            <span># {p.amount}</span>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <div className="col-12">
                                    {(p.status === "notfound") && (
                                        <i class="fa fa-close"  style={{color:'#db1d1d', fontSize:25}}></i>
                                        )} 
                                 </div>
                                <div className="col-12">
                                    {(p.status === "success") &&  (
                                       <i class="fa fa-check"  style={{color:'#27ae60', fontSize:25}}></i>
                                    )}
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
               <div className="col-1"></div>
              </div>
     
               </> })
                        :  <section className="container mt-5">
                            <div className="row justify-content-center align-items-center">
                                    <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>Nothing Found </p>
                                </div>
                             </section>}
  
           </div>
       </div>

    </div>
  </section>

  <section className="container">
        <div className="row mb-5">

        <div className="checkout"></div>

            <div className="cad bottomCheck">
                      {/* <NumericFormat value={cartTotal} displayType={'text'} thousandSeparator={true} prefix={'TOTAL: ₦'}  className="ml-4 p2Cart" /> */}
                      <div>
                        <p style={{color: '#000', fontSize: 15, fontWeight:'bold'}}>Total: # {trans.amount}</p>
                        {(trans.refunded > 0) ? 
                        <p style={{color: '#000', fontSize: 15, fontWeight:'bold'}}>Refund: # {trans.refunded} </p>
                        : null}
                        </div>

                        {(trans.orderStatus === "waiting") && (<>
                                <p style={{color: '#000', fontSize:20, fontWeight:'bold'}}>Waiting for Agent</p>
                            </>)}
                            
                        {(trans.orderStatus === "inTransit") && (
                            <button className="btn btn-md" onClick={()=> recievedItem(trans._id)} style={{backgroundColor:'#27ae60', color:'#fff', borderRadius:22}} disabled={disableBtn}>
                            {disableBtn ? (<>Please Wait..</>) :(
                                <>Confirm Order Recieved</>)}
                            </button>
                            ) }
                            {(trans.orderStatus === "pending") && (<>
                                <p style={{color: '#000', fontSize:20, fontWeight:'bold'}}>Items being purchased</p>
                            </> )}
                            {(trans.orderStatus === "success") && (<>
                                <p style={{color: '#000', fontSize:20, fontWeight:'bold'}}>Order Successfull</p>
                            </> )}
            </div>
         
        <div className="checkout"></div>
        </div>
   </section> 
  
    </>}
            

</section>


 </Layout>

   </>
  );
}

export default OrderDetails;
