import React, {useState, useEffect} from 'react';
import {useParams, useLocation, useNavigate} from "react-router-dom";
import configData from "../../config.json";
import Layout from '../Layout';

const VendorDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { name, id } = useParams();
    const [inputText, setInputText] = useState('');

    const [disableBtn, setDisableBtn] = useState(false);
    const [quantity, setQuantity] = useState();
    const [product, setProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const gobasket = () => {
        window.$(".success-modal-lg").modal("hide");
        navigate(`/Products/Cart/${id}`);
    }
    
    const addToCart = async(prodId) => {
       
    //   if(quantity === ''){
    //       alert('Product quantity is required');
    //       return ;
    //   }
    //   if(quantity <= 0){
    //       alert('Invalid Amount');
    //       return;
    //   }
    //   if(isNaN(quantity)){
    //       alert('Product amount is not a number');
    //       return;
    //   }
      
        
      setDisableBtn(true);
  
      return fetch(`${configData.SERVER_URL}/vendorCart/${prodId}`, {
          method: "post",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token": window.localStorage.getItem("token") 
          },
          body: JSON.stringify({         
            quantity:quantity
          })
      })
      .then((response) => response.json())
      .then((responseJson) => {

        console.log(responseJson)
  
          if(responseJson.status === "success"){
              setDisableBtn(false);
              setQuantity();
              window.$(".success-modal-lg").modal("show");
          }
          if (responseJson.status === "error") {
              setDisableBtn(false)
              alert(responseJson.message);
          }
      })
      .catch((error) => {
        setDisableBtn(false)
          console.error(error);
      });
  
    }
  
    const shop = () => {
      window.$(".success-modal-lg").modal("hide");
    }
  

    const getProd = () => {
        setIsLoading(true);

        return fetch(`${configData.SERVER_URL}/vendorProducts/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token") 
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
              setIsLoading(false)
              setProduct(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false)
          });

    }

    useEffect(() => {
        getProd();
    },[])
    
    return (
        <>
   
 <Layout>


         <section className="box2 border">

 
         <div className="vImg" style={{ 
                backgroundImage: `url(${'/img/food2.jpg'})`,
                // backgroundImage: `url(${process.env.PUBLIC_URL + '/img/food2.jpg'})`, 
                backgroundRepeat: 'no-repeat'}}>

                    <div className="col-md-12 pt-4" style={{zIndex: 10}}>
                        <div className="row">
                            <div className="col-4">
                                <a href="/Brick&Mortar"  className="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#fff'}}></i>
                                </a>
                            </div>
                            <div className="col-4">
                            </div>
                            <div className="col-4">
                               <div onClick={gobasket} style={{ width:"70%", height:"100%", display: 'flex', flexDirection: 'row', justifyContent:"space-around", alignItems: 'center', backgroundColor:'#147439', padding:"2% 3%", borderRadius:"20px", color:'#fff'}}>
                                <span>Cart</span> 
                                <i class="fas fa-shopping-basket d-flex justify-content-center" style={{fontSize: '1.2rem', textAlign:'center', color:'#fff'}}></i>
                               </div>
                            </div>
                        </div>
                    </div>



                   <div className="col-md-12 mt5">
                     <h4 className="vText">{name}</h4>
                     <p className="text-white">{location.state.data.aboutUs}</p>
                     </div>

                     <div className="col-md-12" style={{zIndex: 10}}>
                        <div className="text-white">
                            <div style={{display:"flex", flexDirection:'row'}}>
                               <i class="fas fa-envelope text-white" style={{fontSize: '20px', marginRight:"10px"}}></i>
                                <p className={"textOne"}>{location.state.data.email}</p>
                            </div>
                            <div className="vInfo">
                                <i class="fas fa-phone text-white" style={{fontSize: '20px', marginRight:"10px"}}></i>
                                <p className={"textOne"}>{location.state.data.phone}</p>
                            </div>
                        </div>
                    </div>

                    <div className="overlay"></div>
                 </div>

          


       
            <div className="col-md-12 mt-5">
                    
                <div className="row pr-3 pl-3">
                        <div className="col-md-12">
                               <h5>Product Listing</h5>
                            </div>


 
                        <div className="col-md-12">

                        {isLoading ? (<> 

                                <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                        <span >Loading...</span>
                                    </div>
                                </div>

                                </>) 

                                : (<>
                <div className="row">

                                                   
              {product && product.length > 0
                ? product.map(p => {
                return <>

                <div className="col-6 transOne border">
                    <div className="row">
                        <div className="boxOne col-12">
                            <img className="img-fluid mx-auto d-block prod-img" src={`${configData.PIC_URL}/${p.productImage}`}/>
                        </div>
                        <div className="boxTwo col-12">
                                <div className="col-12 pl-4 pb-2">
                                    <span className='text'>{p.name}</span><br/>
                                    <span className='text'>#{p.price}</span>
                                </div>
                                <div className='col-12'>
                                    <a onClick={()=> addToCart(p._id)} className="col-12 mb-4 cart-btn btn-block">
                                         Add to Basket
                                    </a>
                                </div>
                        </div>
                    </div>
                    </div>
                        
                        </> })
                        :<section className="container mt-5">
                            <div className="row justify-content-center align-items-center">
                                    <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Item Found</p>
                                </div>
                            </section>}

                             </div>

                                        </>)}


                            </div> 


                 </div>

                </div>

          



          {/* Successfull Modal */}
    <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
      <div class="modal-dialog bottom modal-lg">  
        <div class="modal-content">
           <div class="modal-header">
            <p class="" id="myModalLabel">Successfull</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
           </div>
                        <div class="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 ml-lg-3">
                                   {/* <img src="./img/Success.png" width="100" class="d-inline-block align-top" id='success' alt="" /> */}
                                    <div class="col-md-12 mt-2">
                                       <p style={{fontSize:20}}>Item Added, continue to list your items until you are done, then click on the basket to purchase them</p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <button onClick={()=> gobasket()}  type="submit" class="btn btn-white my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>GO TO BASKET</>)}</button>

                                        <button onClick={()=> shop()} type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>CONTINUE SHOPPING</>)}</button>
                                    </div>
                           </div>
                            <div className="col-md-2"></div>
                        </div>
        </div>
      </div>
    </div>
        




          </section>


</Layout>

        </>
    );
};

export default VendorDetails;