import React, {useEffect, useState} from 'react';
import Bar from "../component/Bar";
import Footer from "../component/Footer";
import configData from "../config.json";
import { useForm } from "react-hook-form";

const Login = () => {

    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);


  const loginUser = (data) => {

    setDisablebtn(true);


    return fetch(`${configData.SERVER_URL}/auth`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({            
            phone:data.phoneNumber,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){
            //  alert('yes')
            window.localStorage.setItem("token", responseJson.token);
            window.localStorage.setItem("userId", responseJson.user._id);
            window.localStorage.setItem("fullname", responseJson.user.fullname);
            window.localStorage.setItem("email", responseJson.user.email);
            window.localStorage.setItem("phone", responseJson.user.phone);
            window.localStorage.setItem("balance", responseJson.user.balance);
            window.location = window.location.origin + "/";
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
            alert(responseJson.message);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });
}

    return (

<>
         <div class="row">
             <div class="col-md-1"></div>
             <div class="col-md-10 mt-4 mb-4">
                    <div class="col-md-12 mt-3">                                        
                   
                        <form onSubmit={handleSubmit(loginUser)} enctype="multipart/form-data" id="submit" class="p-4" style={{backgroundColor:"#fff"}}>

                               {/* <div class="alert alert-warning" role="alert">
                                   {message.length ? ({message}) : (null)}
                                    </div>        */}
                            <p class="h4 mb-4">Please Login to Continue</p>    
                            <div class="form-group">
                                <input type="tel" id="phone" class="form-control mb-4" placeholder="Phone Number" name="phoneNumber" ref={register({ required: true, })} />
                                {errors.phoneNumber && <div class="alert alert-danger" role="alert">Phone Number Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" >
                                              {disablebtn ? (<>Please wait</>) : (<>LOGIN</>)}
                                        </button> 
                                </div>


                                <p>Forgotten Password? 
                                    <a href="dashboard"> Click Here</a>
                                    </p>

                                <hr/>

                                <p>If you dont have an account already you can
                                    <a href="/Register"> <em>Register Here</em></a>
                                    </p>

                        </form>
                    </div>
                  </div>
             <div class="col-md-1">              
             </div>
         </div> 


</>

    );
  

}

export default Login;