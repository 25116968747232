import React, {useState, useEffect, useContext} from 'react';
// import {Redirect, Link, useParams} from "react-router-dom";
import configData from "../../config.json";
import { useNavigate } from "react-router-dom";
import Layout from '../Layout';

const VendorIndex = () => {
    const navigate = useNavigate();
    const [inputText, setInputText] = useState('');

    const [city, setCity] = useState([])
    const [product, setProduct] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isStateSelected, setIsStateSelected] = useState(false);
    const [selectedState, setSelectedState] = useState("");

    const SearchVend = async() => {
        const select = window.localStorage.getItem('state');

      return fetch(`${configData.SERVER_URL}/vendor/searchByState/${select}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": window.localStorage.getItem("token") 
        },
        body: JSON.stringify({
          inputText:inputText,
        }) 
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setVendors(responseJson.data);
        })
        .catch((error) => { 
          console.error(error);
        });
    }

    const getVendor = () => {
        setIsLoading(true);
        const select = window.localStorage.getItem('state');

        return fetch(`${configData.SERVER_URL}/vendor/single/${select}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token") 
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
              setIsLoading(false)
              setVendors(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false)
          });

    }

    const getState = () => {
       
        return fetch(`${configData.SERVER_URL}/state`, {
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
                setCity(responseJson.state);
            })
            .catch((error) => {
              console.error(error);
            });
      }
      
    useEffect(() => {
        const select =  window.localStorage.getItem('state')
        if(select) { 
          setIsStateSelected(true)
          getVendor();
        }
        getState();
      },[])
      
      
      const selectStateFunc = (itemValue) => {
             window.localStorage.setItem("state",  itemValue);
             setSelectedState();
             setIsLoading(true);
             setIsStateSelected(true);
             getVendor();
         }

    const nav = (data) => { 
        navigate(`/Store/${data.name}/${data._id}`, {state:{
            data: data
        }});
     }
     
    
    return (
        <>
   
 <Layout>


         <section className="box2 border">

 
       <div className="topNav">
                    <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-4">
                                <a href="/"  className="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </a>
                            </div>
                            <div className="col-4">
                                <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Stores</h4>
                            </div>
                            <div className="col-4">
                                <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                            </div>
                        </div>
                    </div>
                </div>


       
            <div className="col-md-12 mt-5">
                    
                <div className="row pr-3 pl-3">
     {isStateSelected ? <>


        <div className="col-md-12">
            <div className="row">
               <div className="col-md-10">
                  <div class="form-group">
                     <select class="browser-default custom-select" name="selectedState" value={selectedState} onChange={(e) => selectStateFunc(e.target.value)}>
                         <option selected>Change State</option>
                         {city.map(c => (
                             <option value={c._id}>{c.name}</option>
                             ))}
                         </select>
                     </div>
                </div>

             </div>
         </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div class="md-form col-10">
                                    <input placeholder=" Search for your favorite shops Here..." type="text" id="search" class="form-control form-control-sm"                          
                                    onChange={(e) => setInputText(e.target.value)}
                                    value={inputText}/>
                                </div>
                                <div class="md-form col-2">
                                        <span onClick={()=> SearchVend()} style={{cursor:'pointer'}}>
                                            <i class="fas fa-search" id="seac" style={{color:'#fff'}}></i>
                                        </span>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-12">

                        {isLoading ? (<> 

                                <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                        <span >Loading...</span>
                                    </div>
                                </div>

                                </>) 

                                : (<>
                            <div className="row">

                                {vendors && vendors.length > 0
                                    ? vendors.map(v => {
                                        return <>
                                            <div onClick={() => nav(v)} className="col-6 vend">
                                                <img src={`${configData.PIC_URL}/${v.logo}`}  class="d-inline-block align-top img-fluid categ-img" alt="cat"/>
                                                <p className="catName">{v.name}</p>
                                            </div>
                                        </>})
                                            :<section className="container mt-5">
                                            <div className="row justify-content-center align-items-center">
                                                    <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No store found</p>
                                                </div>
                                            </section>}

                                </div>

                            </>)}


                            </div>
                   
                   </>  : <>
                   
          <div style={{ width:'100%', height:'100%', justifyContent:"center", alignItems:"center"}}>
                  
                         <div class="form-group">
                            <label for="exampleForm2">Please select your state to get convenience stores around you</label>

                            <select class="browser-default custom-select" name="selectedState" value={selectedState} onChange={(e) => selectStateFunc(e.target.value)}>
                                <option selected>Choose State here</option>
                                {city.map(c => (
                                    <option value={c._id}>{c.name}</option>
                                    ))}
                                </select>
                            </div>

                </div>
                                  
                   
                   
                   </>
            }


                 </div>

                </div>

                 




         </section>


</Layout>

        </>
    );
};

export default VendorIndex;