import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Layout from './Layout';

function HomePage() {
    const navigate = useNavigate();

    const [activeBtn, setActiveBtn] = useState('');
    const [modalShow, setModalShow] = useState(false);


    const nav = (item) => { 
        navigate(`/${item}`);
     }
     

  return (
    <>
 

   <Layout>
        
         <section className="box2 border">


          <div id="home">

           <div className="topNav">
                <div className="col-md-12">
                <div className="row">
                    <div className="col-6">
                        <h4 className="igobiTitle">IGOBI</h4>
                        <span className="mobileText">mobile</span>
                    </div>
                    <div className="col-6">
                         <Link to="/Profile" className="profIcon">
                            <i class="fas fa-user" style={{fontSize: '20px', color: '#fff', backgroundColor:"#000", padding:'5%', borderRadius:"10px"}}></i>
                         </Link>
                    </div>
                </div>
                </div>
            </div>


            <div className="secOne">
                <div className="col-md-12" id="imgH"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/food.jpg'})`, backgroundRepeat: 'no-repeat' }}>
                    <div className="rw">
                        <div className="col8 mt-4">
                            <p>Online market</p>
                            <p className='p'>Where would you like to shop?</p>
                        </div>
                        <div className="col4">
                           <img src="./img/mascot.png" class="d-inline-block align-top img-fluid imgHeader" alt=""/>
                        </div>
                    </div>
                 </div>

              </div>

              <div className="flexDisplay scrollm">

                    <div onClick={()=> nav("Market")} className={"cadFeature cadOne"}>
                        <div>
                             <img src="./img/LOCAL-MARKET-1.png" className="d-inline-block align-top img-fluid feat-img" alt=""/>
                            <p className={"boxT"}>Community markets</p>
                            <p className={"subp"}>Shop from your community</p>
                            <p className={"sub2"}>markets in your city</p>
                        </div>
                    </div>

                    <div onClick={()=> nav("Brick&Mortar")}  className={"cadFeature cadTwo"}>
                        <div>
                             <img src="./img/BRICKANDMORTA.png" className="d-inline-block align-top img-fluid feat-img2" alt=""/>
                            <p className={"boxT"}>Convenience Stores</p>
                            <p className={"subp"}>Buy from any convenience/online</p>
                            <p className={"sub2"}>store of your choice</p>
                        </div>
                    </div>

                    <div onClick={() => nav("Farm/Products")} className={"cadFeature cadThree"}>
                        <div>
                             <img src="./img/FARM-PRODUCTS-1.png" className="d-inline-block align-top img-fluid feat-img2" alt=""/>
                            <p className={"boxT"}>Farm Products</p>
                            <p className={"subp"}>Buy directly from farmers</p>
                            <p className={"sub2"}>in Nigeria</p>
                        </div>
                        
                    </div>

                    <div onClick={() => nav("Artisans")} className={"cadFeature cadFour"}>
                        <div>
                             <img src="./img/ARTISANS-1.png" className="d-inline-block align-top img-fluid feat-img3" alt=""/>
                            <p className={"boxT"}>Artisans</p>
                            <p className={"subp"}>Connect and deal with professional</p> 
                            <p className={"sub2"}>artisans in your city</p>
                        </div>
                        
                    </div>

              </div>


          </div>




         </section>
  
   </Layout>
     
 
    </>
   );
 }
 
 export default HomePage;
 