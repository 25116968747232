import React, {useEffect, useState} from 'react';
import configData from "../config.json";
import { useForm } from "react-hook-form";

const DeleteAccount = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);


  const loginUser = async(data) => {

    setDisablebtn(true);


    return fetch(`${configData.SERVER_URL}/auth`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({            
            phone:data.phoneNumber,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){
            alert('Your account has been successfully deleted')
            window.location = window.location.origin + "/";
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
            alert(responseJson.message);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });
   }

    return (

<>
         <div class="row">
             <div class="col-lg-2"></div>
             <div class="col-lg-8 mt-4 mb-4">
                    <div class="col-md-12 mt-3">                                        
                   
                        <form onSubmit={handleSubmit(loginUser)} enctype="multipart/form-data" id="submit" class="p-4" style={{backgroundColor:"#fff"}}>

                               {/* <div class="alert alert-warning" role="alert">
                                   {message.length ? ({message}) : (null)}
                                    </div>        */}
                            <p class="h4 mb-4">Please enter your email and password to delete account</p>    
                            <div class="form-group">
                                <input type="tel" id="phone" class="form-control mb-4" placeholder="Phone Number" name="phoneNumber" ref={register({ required: true, })} />
                                {errors.phoneNumber && <div class="alert alert-danger" role="alert">Phone Number Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" >
                                              {disablebtn ? (<>Please wait</>) : (<>Delete</>)}
                                        </button> 
                                </div>
                                
                        </form>
                    </div>
                  </div>
             <div class="col-lg-2">              
             </div>
         </div> 


</>

    );
  

}


export default DeleteAccount;