import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Login from './Login';

const Layout = ({children}) => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    
    useEffect(() => {
         
        const token =  localStorage.getItem('token');

        if(!token){
            handleShow() 
        }


  },[])
    
    return (<>
      
<section class="contain">
     <div className="tabBoxParent">
         <section className="box1">
         
         </section>
                            
                 {children}
                 

          {/* <GoToTop/> */}

          <section className="box1">
 
            </section>
        </div>
 </section>
 
      
 <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
             <Login/>
        </Modal.Body>
   </Modal>
       {/* Login modal */}
       {/* <div class="modal fade login" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                <div class="modal-dialog bottom modal-lg">  
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="" id="myModalLabel"></p>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="row">
                            <Login/>
                        </div>
                    </div>
                </div>
            </div> */}
            
                </> );
};

export default Layout;