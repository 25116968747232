
 import React, {useContext, useState, useEffect} from "react";
 import { useForm } from "react-hook-form";
 import configData from "../config.json";
import { useNavigate } from 'react-router-dom';
 
 const Button = () => {
  let navigate = useNavigate();
    const { register, handleSubmit, watch, errors } = useForm();
   const [disableBtn, setDisableBtn] = useState(false);
   const [productName, setProductName] = useState('');
   const [amount, setAmount] = useState();
   const [item, setItem] = useState('');
 


   const addToCart = async() => {
     if(amount === ''){
         alert('Product amount is required')
         return
     }
     if(amount <= 0){
         alert('Invalid Amount');
         return;
     }
     if(isNaN(amount)){
         alert('Product amount is not a number');
         return;
     }
     if(productName === ''){
       alert('Product Name is required')
       return
      }
    // alert(productName + '' + amount)
    // return
     const token = await localStorage.getItem('token');
     const userId = await localStorage.getItem('userId');
       
     setDisableBtn(true)
 
     return fetch(`${configData.SERVER_URL}/cart/addItem/2134567890`, {
         method: "post",
         headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "x-auth-token": token
         },
         body: JSON.stringify({   
             productName:productName,      
             amount:amount,
             userId:userId
         })
     })
     .then((response) => response.json())
     .then((responseJson) => {
 
         if(responseJson.status === "success"){
           setDisableBtn(false)
           setAmount();
           setProductName('');
           window.$("#exampleModal").modal("hide");
           window.$(".success-modal-lg").modal("show");
         }
         if (responseJson.status === "error") {
             setDisableBtn(false)
             alert(responseJson.message);
         }
     })
     .catch((error) => {
       setDisableBtn(false)
         console.error(error);
     });
 
   }
 
   const shop = () => {
    window.$(".success-modal-lg").modal("hide");
  }

  const basket = () => {
    window.$(".success-modal-lg").modal("hide");
    navigate("/Basket");
  }

  const whatsapp = () => {
    window.location.href = "https://wa.me/+2348064080625/?text=urlencodedtext";
    // history.push('https://wa.me/+2348064080625/?text=urlencodedtext')
        
  }

 
   return (
     <>
     
    
 

     <span className="float-btn">
            <a className="btn btn-floating btn-sm btn-warn" type="button" data-toggle="modal" data-target="#exampleModal">
            <i class="fas fa-plus" id="icon"></i> 
            <p>write your list</p>
            </a>
        </span>
      



             {/* write list modal              */}
     <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form  onSubmit={handleSubmit(addToCart)} method="post" enctype="multipart/form-data" class="" style={{paddingTop:"20px", paddingLeft:"20px", paddingRight:"20px"}}>
                
                    <h4 style={{color:"#000"}}>Please input item and amount</h4>

                    <div class="form-group">
                        <input type="text" class="form-control mt-4" placeholder="Item Name" name="productName" value={productName} onChange={e => setProductName(e.target.value)}/>
                        {errors.productName && <small id="emailHelp" class="form-text text-muted">Please add Item Name.</small>}
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control mt-4" id="exampleInputEmail1" name='amount' placeholder="Amount (NGN)" value={amount} onChange={e => setAmount(e.target.value)}/>
                        {errors.amount && <small id="emailHelp" class="form-text text-muted">Please add Item Name.</small>}
                    </div>

                    <div class="mb-5">
                        <button class="btn btn-warn btn-block" name="submit" disabled={disableBtn}>
                          {(disableBtn) ? ( <>Please wait</>) : (<> Add To Basket</>)}
                        </button>
                    </div>

                    <div onClick={()=> whatsapp()} style={{width: '100%', textAlign:'center'}}>
                            <span style={{fontSize: '18px', fontWeight:'bold', color: '#147439', textAlign:'center'}}>need help?  
                                <i class="fa fa-whatsapp" style={{fontSize: '18px', color: '#147439'}}></i>
                            </span>
                        </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>


          {/* Successfull Modal */}


  <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
      <div class="modal-dialog bottom modal-lg">  
        <div class="modal-content">
           <div class="modal-header">
            <p class="" id="myModalLabel">Successfull</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
           </div>
                        <div class="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 ml-lg-3">
                                   {/* <img src="./img/Success.png" width="100" class="d-inline-block align-top" id='success' alt="" /> */}
                                    <div class="col-md-12 mt-2">
                                       <p style={{fontSize:20}}>Item Added, continue to list your items until you are done, then click on the basket to purchase them</p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        {/* <button onClick={()=> basket()}  type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>GO TO BASKET</>)}</button> */}

                                        <button onClick={()=> shop()} type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>CONTINUE SHOPPING</>)}</button>
                                    </div>
                           </div>
                            <div className="col-md-2"></div>
                        </div>
        </div>
      </div>
    </div>


 
     </>
   );
 }
 
   
 
 export default Button;
 