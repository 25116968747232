import React, { useState, useEffect } from 'react';
import configData from "../../config.json";
import { format } from 'date-fns' 
import Layout from '../Layout'; 
import Offcanvas from 'react-bootstrap/Offcanvas';

const Transactions = () => {
    const [trans, setTrans] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [transItem, setTransItem] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  
    const transaction = async() => {
      
      return fetch(`${configData.SERVER_URL}/transaction/vendorTrans`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        setTrans(responseJson.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  
   };
  
   useEffect(() => {
     
    // const interval=setInterval(()=>{
      transaction();
    //  },3000)
      
    //  return()=>clearInterval(interval)
  
  },[]);

   
  

   const viewMore = async(data) => {
         handleShow()

         return fetch(`${configData.SERVER_URL}/transactionItem/${data.reference}`, {
           method: "get",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "x-auth-token": window.localStorage.getItem("token")
             },
           })
           .then((response) => response.json())
           .then((responseJson) => {
             console.log(responseJson)
             setTransItem(responseJson.transItem);
             setLoading(false);
           })
           .catch((error) => {
             setLoading(false);
             console.error(error);
           });
    }

    
    return (
        <>
            

       <Layout>
          <div className="mt-4">

                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div className="">
                           <p className="title2">TRANSACTIONS</p>
                        </div>
                    </div>

                <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">

            {isLoading ? <>Loading </> : <>
                    <div className="table-responsive">
                        <table className="table white black-text  table-hover">
                            <thead>
                                <tr>
                                <th scope="col">Users Name</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Date</th>
                                </tr>
                            </thead>
                        <tbody>             
                    {trans.map(t => (
                                <tr className="" key={t._id}>
                                <td>{t.userName}</td>
                                <td>₦ {t.amount}</td>
                                <td>{t.orderStatus}</td>
                                <td>{format(new Date(t.createdAt), 'MMMM, do, yyy')}</td>                             
                                <td onClick={() => viewMore(t)} style={{cursor:'pointer', backgroundColor:'green', color:'#fff'}}>view</td>
                                </tr>
                        ))}

                        </tbody>
                        </table>
                    </div>
                    </>}


                    </div>
                </div>
                </div>

            </div>
  
  
          </Layout>



      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Transaction Items</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {loading ? <>
            
            </>
              : <>


                <div className="col-md-12 mt-3">

                <div className="table-responsive">
                  <table className="table white black-text  table-hover">
                      <thead>
                          <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Amount</th>
                          </tr>
                      </thead>
                    <tbody>
                {transItem.map((t, index) => (
                          <tr key={index} className="">
                            <td>
                                <img class="img-fluid img-thumbnail tableImg" src={`${configData.PIC_URL}/${t.productImage}`} width="100%"/></td>
                            <td>{t.productName}</td>
                            <td>#{t.amount}</td>                           
                          </tr>
                  ))}

                    </tbody>
                  </table>
                </div>
                </div>

              </>}
        </Offcanvas.Body>
      </Offcanvas>

  
        </>
    );
};

export default Transactions;