import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import configData from "../../config.json";
import { format } from 'date-fns' 

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trans, setTrans] = useState([])
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(false);
  // const [productImage, setProductImage] = useState('')
  const productImage =  localStorage.getItem('logo');


  const getProduct = async() => {
         
    return fetch(`${configData.SERVER_URL}/vendorProducts/vendors/Product`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          setProduct(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }


  const transaction = async() => {
      
    return fetch(`${configData.SERVER_URL}/transaction/vendorTrans`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token":  window.localStorage.getItem("token")
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      setTrans(responseJson.data);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });

 };

 useEffect(() => {
  transaction();
   getProduct();
    
    // const interval=setInterval(()=> {
    //   transaction();
    // },3000)
      
    // return()=>clearInterval(interval)

},[]);

 

    return (
        <>


       <Layout>

            <div class="mt-2">

            <div className="welcm-col mb-3">
                 <img src={`${configData.PIC_URL}/${productImage}`} className="img-fluid img-thumbnail tableImg" alt=""/>
              </div>

              <div className="welcm-col">
                 <h4>Welcome to your Dashboard</h4>
                 <p>This is our convinience store admin dashboard. You can upload products, categories and manage your store's online activities from this dashboard.</p>
              </div>






                <div className="box-parent mt-4">
                        <div class="text-white px-3 py-4 box-dash">
                            <h4 class="mb-4">Products</h4>
                            <p class="small mb-0">{product.length}</p>
                        </div>
                        <div class="text-white px-3 py-4 box-dash">
                            <h4 class="mb-4">Transactions</h4>
                            <p class="small mb-0">{trans.length}</p>
                        </div>
                        <div class="text-white px-3 py-4 box-dash" style={{backgroundColor:"#fff"}}>
                            {/* <h4 class="mb-4">Daily Amount</h4>
                            <p class="small mb-0"></p> */}
                        </div>
                </div>


                <div className="col-md-12">
                <div className="row">
                    <div className="col-md-8 mt-5">

            {/* {isLoading ? <> <p>Loading...</p> </> : <>
                   
                   
                   <p className="title2">Current Transactions</p>

                  {trans.length > 0 ? 
                      <div className="table-responsive">
                                  <table className="table white black-text  table-hover">
                                      <thead>
                                          <tr>
                                          <th scope="col">Users Name</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Order Status</th>
                                          <th scope="col">Date</th>
                                          </tr>
                                      </thead>
                                  <tbody>             
                              {trans.map(t => (
                                          <tr className="" key={t._id}>
                                          <td>{t.userName}</td>
                                          <td>₦ {t.amount}</td>
                                          <td>{t.orderStatus}</td>
                                          <td>{format(new Date(t.createdAt), 'MMMM, do, yyy')}</td>                             
                                          </tr>
                                  ))}

                                  </tbody>
                                  </table>
                              </div>
                          : <>
                          
                          <div style={{width: '100%', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                             <p>You dont have a transaction yet</p>

                          </div>
                          
                          
                          </>}
                
                    </>} */}


                    </div>
                </div>
                </div>

             </div>

          </Layout>

            
        </>
    );
};

export default Dashboard;