import React, {useState, useEffect}  from 'react';
import configData from "../config.json";
function Categories() {
    const [disableBtn, setDisableBtn] = useState(false);
    const [cat, setCat] = useState([])
    const [isLoading, setIsLoading] = useState(true);



    const getCat = () => {
      
        return fetch(`${configData.SERVER_URL}/category`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // "x-auth-token": JSON.parse(AsyncStorage.getItem("token"))
            },
          })
          .then((response) => response.json())
          .then((responseJson) => {
              setCat(responseJson.category);
              setIsLoading(false)
          })
          .catch((error) => {
            console.error(error);
          });
      }
    
      useEffect(() => {
        getCat();
      },[]);


    return (
        <>


<section class="contain">
     <div className="row">

<section className="box1">
           
           </section>
   
<section className="boxN2 border">


       <div className="topNav">
                    <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-4">
                                <a href="/"  class="thmb-img">
                                    <i class="fas fa-arrow-left" style={{fontSize: '20px', color: '#000'}}></i>
                                </a>
                            </div>
                            <div className="col-4">
                                <h4  style={{color: '#000',  width:'30%', marginTop: '10px', fontSize: '18px', textAlign:'center', fontWeight:'bold'}}>Deals</h4>
                            </div>
                            <div className="col-4">
                                <img src="./img/mascot.png"  class="d-inline-block align-top  float-right" alt="mascout" width="35px"/>
                            </div>
                        </div>
                    </div>
                </div>


       
                <div className="col-md-12 mt-4">
                    <div className="row pr-3 pl-3">


                 {isLoading ? (<> 

                        <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                <span >Loading...</span>
                            </div>
                         </div>

                        </>) 

                        : (<>

                    {cat && cat.length > 0
                ? cat.map(cat => {
                    return <>
                        <div className="col-6">
                            <img src={`${configData.PIC_URL}/${cat.image}`}  class="d-inline-block align-top img-fluid categ-img" alt="cat"/>
                            <p className="catName">{cat.name}</p>
                        </div>
                    </>})
                        :<section className="container mt-5">
                        <div className="row justify-content-center align-items-center">
                                <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Category Found</p>
                            </div>
                         </section>}

                                </>)}


                     </div>
                 </div>

                 


</section>


<section className="box1">
           
           </section>

     </div>
 </section>



            
        </>
    );
}

export default Categories;