import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import {Redirect, Link, useParams} from "react-router-dom";
// import configData from "../config.json";
import { useForm } from "react-hook-form";

function Bar() {
    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);

    useEffect(() => {

        $("[data-trigger]").on("click", function(){
            var trigger_id =  $(this).attr('data-trigger');
            $(trigger_id).toggleClass("show");
            $('body').toggleClass("offcanvas-active");
        });
    
        // close if press ESC button 
        $(document).on('keydown', function(event) {
            if(event.keyCode === 27) {
               $(".navbar-collapse").removeClass("show");
               $("body").removeClass("overlay-active");
            }
        });
    
        // close button 
        $(".btn-close").click(function(e){
            $(".navbar-collapse").removeClass("show");
            $("body").removeClass("offcanvas-active");
        }); 
    

    }, []);


  return (
   <>

<div class="header_top">
           <div class="container">
               <div class="row">
                   <div class="col-6">
                       <div class="social-icons">
                           <ul class="nav nav-pills">
                               <li><span class="top-text">Connect with us:</span></li>
                               <li><a href="#" class="top-text"><i class="fa fa-twitter"></i></a></li>
                               <li><a href="#" class="top-text"><i class="fa fa-facebook"></i></a></li>
                               <li><a href="#" class="top-text"><i class="fa fa-instagram"></i></a></li>
                             </ul>
                       </div>						
                   </div>
                   <div class="col-6">
                         <div class="pull-right">
                           <ul class="nav nav-pills">
                               <li><a href="#" class="top-text"><i class="fa fa-phone"></i> <span>Customer Care</span></a></li>  
                               <li><a href="#" class="top-text"><i class="fa fa-user"></i><span> About Us</span></a>
                                 </li>                    
                               <li><a href="#" class="top-text"><i class="fa fa-envelope"></i> <span>info@igobing.com</span></a></li>
                           </ul>
                       </div>
                   </div>
               </div>
           </div>
       </div>

<div class="container py-2">
    <div className="row">
        <div className="col-md-2">
           <img src="./img/icon.png"  class="d-inline-block align-top logo" alt="" />
        </div>
        <div className="col-md-6">
                       <form method="get" action="results" enctype="multipart/form-data" class="search_box text-center">
                           <div class="md-form input-with-post-icon">
                            {/* <i class="fa fa-search  input-prefix"></i> */}
                             <input placeholder=" Search entire store here..." type="text" id="search" class="form-control form-control-sm"/>
                           </div>
                       </form>
           </div>
        <div className="col-md-4">

        <div class="shop-menu pull-right" id="nav1">
                                   <ul class="nav">
                                       <li>
                                         <Link to="/">
                                             <i class="fa fa-home"></i>
                                             <span> Home</span></Link>
                                        </li>
                                       <li>
                                         <Link to="/Market">
                                             <i class="fa fa-shop"></i>
                                             <span> Market</span></Link>
                                        </li>
                                       <li>
                                          <Link to="/Basket" id="cart">
                                              <i class="fa fa-shopping-cart mr-2"></i>
                                              Basket 
                                            <span> 0</span>
                                          </Link>
                                        </li>
                                   {(window.localStorage.getItem("userId")) ? (<>
                                       <li>
                                         <Link to="/Profile">
                                             <i class="fa fa-user"></i>
                                             <span> Profile</span></Link>
                                        </li>
                                     </>) : (<>
                                     <li>
                                         <Link to="/Login">
                                             <i class="fa fa-lock"></i>
                                             <span> Login</span></Link>
                                        </li>
                                   </>)}
                                   </ul>
                               </div>

        </div>
    </div>
</div>




   </>
  );
}

export default Bar;
