import React, {useState, useEffect} from 'react';
import Sidebar from './Vendor/components/Sidebar';
import configData from "../config.json";
import {useNavigate } from "react-router-dom";

const Layout = ({children}) => {
    const [show, setShow] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    
  //   useEffect(() => {
         
  //       const token =  localStorage.getItem('token');

  //       if(!token){
  //           navigate("/Vendor");
  //            return 
  //       }
  //       if(token){
             
  //        fetch(`${configData.SERVER_URL}/vendor/vendorProfile`, {
  //           method: "get",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             "x-auth-token":  window.localStorage.getItem("token")
  //           },
  //         })
  //           .then((response) => response.json())
  //           .then((responseJson) => {
  //             console.log(responseJson)
  //             if(responseJson.status === "error") {
  //               // localStorage.getItem('token');
  //               // localStorage.getItem('logo');
  //               // localStorage.getItem('mortar');
  //                navigate("/Vendor");
  //                 return 
  //            }
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //           });
      
    
  //       }


  // },[])
    
    return (<>
            
        <section className="vendBoxParent">
                <section className="vendbox1">
                   <Sidebar/>
                </section>

                <section className="vendbox2">
                                    
                                    {children}
        
                    </section>
        </section>
        
            
            
                </> );
};

export default Layout;